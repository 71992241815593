import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Cookies from 'js-cookie';

import axios from './services/axios';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyD7FhpvtaDs60oJlRcwTYyckPghMpHKxOo",
  authDomain: "dsa-unioleo.firebaseapp.com",
  projectId: "dsa-unioleo",
  storageBucket: "dsa-unioleo.appspot.com",
  messagingSenderId: "152699234551",
  appId: "1:152699234551:web:21b3d507c7916d5b228bf2"
};

function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);


      // Initialize Firebase Cloud Messaging and get a reference to the service
      const messaging = getMessaging(app);
      const isLoggedIn = Cookies.get('isLoggedIn');
      if (isLoggedIn) {
        getToken(messaging, {
          vapidKey: 'BIMyrfynheTQ1qwgcLrjJmvqv_LWW0Ua3H6xw7eh-GxLkLKYU-encCZI040gW4ljgOCes-ZgDyXZODiop-4bSZw'
        }).then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            axios.post('/auth/fcm', { token: currentToken })
              .then(res => {
                // console.log(res);
              })
              .catch(err => {
                // console.log(err);
              })

          } else {
            // Show permission request UI
            // console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        }).catch((err) => {
          // console.log('An error occurred while retrieving token. ', err);
          // ...
        });
      } else {
        // console.log('Unable to get permission to notify.');
      }
    }
  });
}
// if browser supports notifications
if ('Notification' in window) {
  requestPermission();
}

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage((payload) => {
//       resolve(payload);
//     });
//   });