import { useState, useEffect } from 'react';
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import { Card, Container, Grid, Button, Table, TableHead, TableRow, TableCell, styled, alpha, InputBase, TablePagination, TableBody } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";
import ConfirmDelete from '../../components/ConfirmDelete';

// import fileDownload from 'js-file-download';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Dashboard() {
  const { t } = useTranslation();
  const location = useLocation();
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');


  const fetchData = async () => {
    try {
      const result = await axios.get(`/user/dca-audit/list?page=${page}&limit=${rowsPerPage}&search=${search}`);
      setData(prev => result.data.data.results);
      setTotal(result.data.data.total);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().map((item) => toast.error(item));
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, rowsPerPage, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIdDelete(null);
    fetchData();
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`/admin/dca-answer/${id}`);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      handleCloseDelete();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const loc = location.pathname;
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    getSubmenu();
    // eslint-disable-next-line
  }, [])

  // const exportExcel = async () => {
  //   try {
  //     const result = await axios.get(`/user/project-audit/excel?search=${search}`, {
  //       responseType: 'blob'
  //     });
  //     const filename = result.headers['content-disposition'].split('filename=')[1];
  //     fileDownload(result.data, filename);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  if (loading) return <Loading />

  return (
    <Container>
      <h1>{title}</h1>
      <ConfirmDelete open={openDelete} onClose={handleCloseDelete} handleDelete={handleDelete} id={idDelete} />
      <Card sx={{ padding: 5 }}>
        <Grid container justifyContent={"space-between"} alignItems="center" sx={{ padding: 2 }}>
          <Grid item>
            <Grid container alignItems="center" spacing={1} justifyContent="flex-start">
              {/* <Grid item xs="auto">
                <Button variant="contained" onClick={exportExcel}>
                  Export
                </Button>
              </Grid> */}
              <Grid item xs="auto">
                <Search>
                  <SearchIconWrapper>
                    <FontAwesomeIcon icon={"fa-solid fa-magnifying-glass"} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    value={search}
                    onChange={(e) => handleSearch(e)}
                    placeholder={`${t("component.text.search")}...`}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('component.text.location')}</TableCell>
              <TableCell align="center">{t('component.text.date')}</TableCell>
              <TableCell align="center">{t('component.text.auditor')}</TableCell>
              <TableCell align="center">Audit Form</TableCell>
              <TableCell align="center">Noncompliance</TableCell>
              <TableCell align="center">{t('component.text.auditee')}</TableCell>
              <TableCell align="center">{t('component.text.action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
              <TableRow>
                <TableCell colSpan={10} align="center">{t('component.text.noData')}</TableCell>
              </TableRow>
            )}
            {data.map((item, index) => (
              <TableRow key={index} >
                <TableCell align="center">{item.location}</TableCell>
                <TableCell align="center">{new Date(item.date * 1000).toLocaleDateString('id-ID')}</TableCell>
                <TableCell align="center">{item.userName}</TableCell>
                <TableCell align="center">{item.formName}</TableCell>
                <TableCell align="center">{item.noncompliance}</TableCell>
                <TableCell align="center">{item.name}</TableCell>
                <TableCell align="center">
                  <Grid container spacing={1} justifyContent="center" alignItems="center" direction="row">
                    <Grid item xs={12}>
                      <Link to={`/dca-audit/list/${item.id}`}>
                        <Button variant="contained" color="primary" size="small">
                          {t('component.button.detail')}
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      {(JSON.parse(localStorage.getItem("user")).roles.find(role => role.roleId === 'SFT') || JSON.parse(localStorage.getItem("user")).roles.find(role => role.roleId === 'SFT EXT') || JSON.parse(localStorage.getItem("user")).roles.find(role => role.roleId === 'ADM')) && (
                        <Button variant="contained" color="danger" size="small" onClick={(e) => {
                          setOpenDelete(prev => true);
                          setIdDelete(prev => item.id);
                        }}>{t('component.button.delete')}</Button>
                      )}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
