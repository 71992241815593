import { Box, Card, Container, Grid, Typography } from "@mui/material";

import warning from "../assets/img/warning.svg";

const style = {
  maxHeight: '250px',
  display: 'block',
  marginTop: '40px',
  marginBottom: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
}

export default function ActivateSuccess() {
  return (
    <Container>
      <Card>
        <Grid container spacing={2} sx={{ marginBottom: 5 }}>
          <Grid item xs={12}>
            <img src={warning} alt="warning" style={style} className="img-fluid" />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h3" color="primary">
                Account not found.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Container>
  )
}