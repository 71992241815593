import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function List({ data }) {
  const { t } = useTranslation();

  return (
    <div>
      <TextField autoFocus margin="dense" name="auditor" label={t("component.text.auditor")} type="text" fullWidth value={data.user.name} />
      <TextField autoFocus margin="dense" name="location" value={data.location} label={t("component.text.location")} type="text" fullWidth />
      <TextField autoFocus margin="dense" name="auditee" label={t("component.text.auditee")} type="text" fullWidth value={data.name} />
      <TextField
        label={t("component.text.dateTime")}
        value={new Date(data.date * 1000).toLocaleString("id-ID")}
        name="datetime"
        type="text"
        fullWidth
        autoFocus
        margin="dense"
      />
      <Divider sx={{ marginTop: 5 }} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("component.text.section")}</TableCell>
            <TableCell>{t("component.text.question")}</TableCell>
            <TableCell>{t("component.text.answer")}</TableCell>
            <TableCell>{t("component.text.comment")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.dcaForm.dcaFormCategory.map((category, index) => {
            return category.dcaFormQuestion.map((question, index) => {
              return index === 0 ? (
                <TableRow key={question.id}>
                  <TableCell rowSpan={category.dcaFormQuestion.length}>
                    {localStorage.getItem("language") === "en" ? category.nameEn : category.nameId}
                  </TableCell>
                  <TableCell>{localStorage.getItem("language") === "en" ? question.nameEn : question.nameId}</TableCell>
                  <TableCell>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="answer"
                        value={data.dcaFormAnswerDetail.find((answer) => answer.dcaFormQuestionId === question.id)?.answer}
                      >
                        <FormControlLabel value="1" control={<Radio />} label={t("component.text.yes")} />
                        <FormControlLabel value="0" control={<Radio />} label={t("component.text.no")} />
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="outlined-multiline-flexible"
                      label={t("component.text.comment")}
                      multiline
                      rows={4}
                      name="comment"
                      value={data.dcaFormAnswerDetail.find((answer) => answer.dcaFormQuestionId === question.id)?.comment}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={question.id}>
                  <TableCell>{localStorage.getItem("language") === "en" ? question.nameEn : question.nameId}</TableCell>
                  <TableCell>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="answer"
                        value={data.dcaFormAnswerDetail.find((answer) => answer.dcaFormQuestionId === question.id)?.answer}
                      >
                        <FormControlLabel value="1" control={<Radio />} label={t("component.text.yes")} />
                        <FormControlLabel value="0" control={<Radio />} label={t("component.text.no")} />
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="outlined-multiline-flexible"
                      label={t("component.text.comment")}
                      multiline
                      rows={4}
                      name="comment"
                      value={data.dcaFormAnswerDetail.find((answer) => answer.dcaFormQuestionId === question.id)?.comment}
                    />
                  </TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
    </div>
  );
}
