import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { activate } from "../../services/auth";

import ActivateSuccess from "../../components/ActivateSuccess";
import ActivateFailed from "../../components/ActivateFailed";
import ActivateWaiting from "../../components/ActivateWaiting";

import logo1 from "../../assets/img/logo1.png";

export default function ActivateAcc() {
  const { token } = useParams();
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(true);

  const activateAcc = async () => {
    try {
      const res = await activate(token);
      if (res.status >= 200 && res.status < 300) {
        setFound(true);
      } else {
        res.data.message.reverse().forEach(message => {
          toast.error(message);
        });
        setFound(false);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    document.title = `Account Activation - ${process.env.REACT_APP_NAME}`;
    activateAcc();
    // eslint-disable-next-line
  }, []);


  return (
    <Container>
      <img src={logo1} alt="logo" style={{ marginBottom: 50 }} />
      {found ? <ActivateSuccess /> : loading ? <ActivateWaiting /> : <ActivateFailed />}
    </Container>

  )
}
