import { useState, useEffect } from 'react';
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Container, Grid, Button, Typography, TextField, Autocomplete, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";
import { DateTimePicker } from '@mui/x-date-pickers';

export default function Form() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);

  const [findings, setFindings] = useState([]);
  const [search, setSearch] = useState('');

  const [areas, setAreas] = useState([]);
  const [searchArea, setSearchArea] = useState('');

  const [totalBefore, setTotalBefore] = useState([1]);
  const [expanded, setExpanded] = useState(false);
  const [totalData, setTotalData] = useState(1);
  const [data, setData] = useState([{ imagesBefore: [] }]);

  const priorities = [
    { value: 1, label: t('component.text.low') },
    { value: 2, label: t('component.text.medium') },
    { value: 3, label: t('component.text.high') },
  ];

  const [area, setArea] = useState(null);

  const fetchFinding = async () => {
    try {
      const res = await axios.get(`/master/finding?search=${search}`);
      setFindings(res.data.data.results.map(item => ({
        value: item.id,
        label: item.type
      })));
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  const fetchArea = async () => {
    try {
      const res = await axios.get(`/master/area${searchArea ? `?search=${searchArea}` : ''}`);
      setAreas(res.data.data.results.map(area =>
      ({
        value: area.id,
        label: `${area.name} - PIC: ${area.pic.map(pic => pic.name).join(', ')}`
      })
      ));
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const loc = location.pathname;
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    getSubmenu();
    fetchFinding();
    fetchArea();
    // eslint-disable-next-line
  }, [search, searchArea])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const datas = data.map(item => ({
      areaId: area.value,
      findingId: item.finding.value,
      description: item.description,
      date: item?.datetime ? Math.floor(item.datetime.getTime() / 1000) : Math.floor(new Date().getTime() / 1000),
      solution: item.solution,
      priority: item.priority.value,
      imagesBefore: item.imagesBefore,
    }))

    try {
      const res = await Promise.all(datas.map(async item =>
        await axios.post('/user/smat', item, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      ))

      res.forEach(item => {
        toast.success(item.data.message);
      })
      // res.data.message.reverse().forEach(message => {
      //   toast.success(message);
      // });
      navigate('/management-safety-audit');
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
    setLoading(false);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) return <Loading />

  return (
    <Container>
      <h1>{title}</h1>
      {area ? (
        <>
          <Card sx={{ padding: 5, mb: 5 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>{t('page.smat.create')}</Typography>
            <Typography variant="h5" sx={{ mb: 3 }}>{area.label}</Typography>
          </Card>
          <form onSubmit={handleSubmit}>
            {Array.from(Array(totalData).keys()).map((_, i) => (
              <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs>
                      <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        {`SMAT #${i + 1}`}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography sx={{ color: 'text.secondary' }}>{data[i]?.description}</Typography>
                    </Grid>
                    <Grid item xs>
                      <Button variant="contained" color="danger" onClick={(e) => {
                        e.preventDefault();
                        if (totalData > 1) {
                          setData(data.filter((_, id) => id !== i));
                          setTotalData(totalData - 1);
                        }
                      }}>
                        {t('component.button.delete')}
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>

                  <Autocomplete
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(event, value) => {
                      const newData = [...data];
                      newData[i].finding = value;
                      setData(newData);
                    }}
                    onKeyUp={(event) => setSearch(event.target.value)}
                    id="combo-box-demo"
                    value={data[i]?.finding}
                    options={findings}
                    sx={{ width: '100%' }}
                    renderInput={(params) => {
                      params.fullWidth = true;
                      params.margin = "dense";
                      params.required = true;
                      return <TextField {...params} label={t('component.text.finding')} />
                    }}
                  />
                  <DateTimePicker
                    label={t('component.text.dateTime')}
                    value={data[i]?.datetime ?? new Date()}
                    name="datetime"
                    ampm={false}
                    inputFormat="DD/MM/YYYY HH:mm"
                    onChange={(newValue) => {
                      const newData = [...data];
                      newData[i].datetime = newValue;
                      setData(newData);
                    }}
                    renderInput={(params) => {
                      params.fullWidth = true;
                      params.margin = "dense";
                      params.required = true;
                      return <TextField {...params} />
                    }}
                  />
                  <TextField
                    margin="dense"
                    autoFocus
                    fullWidth
                    multiline
                    rows={3}
                    name="description"
                    label={t("component.text.description")}
                    value={data[i]?.description}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[i].description = e.target.value;
                      setData(newData);
                    }}
                    type="text"
                    required
                  />
                  <TextField
                    margin="dense"
                    autoFocus
                    fullWidth
                    multiline
                    rows={3}
                    name="solution"
                    label={t("component.text.solutionSuggested")}
                    value={data[i]?.solution}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[i].solution = e.target.value;
                      setData(newData);
                    }}
                    type="text"
                    required
                  />
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(event, value) => {
                      const newData = [...data];
                      newData[i].priority = value;
                      setData(newData);
                    }}
                    value={data[i]?.priority}
                    id="combo-box-demo"
                    options={priorities}
                    sx={{ width: '100%' }}
                    renderInput={(params) => {
                      params.fullWidth = true;
                      params.margin = "dense";
                      params.required = true;
                      return <TextField {...params} label={t('component.text.priority')} />
                    }}
                  />
                  {Array.from(Array(totalBefore[i]).keys()).map((item, index) => (
                    <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2} key={index}>
                      <Grid item xs={8}>
                        <TextField
                          margin="dense"
                          autoFocus
                          fullWidth
                          name="image"
                          label={t("component.text.imageBefore")}
                          value={data[i]?.imagesBefore[index]?.name ?? ''}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          component="label"
                          fullWidth
                        >
                          {t('component.button.upload')}
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={(e) => {
                              const newData = [...data];
                              newData[i].imagesBefore[index] = e.target.files[0];
                              setData(newData);
                            }}
                          />
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          component="label"
                          color="danger"
                          fullWidth
                          onClick={() => {
                            if (totalBefore[i] > 1) {
                              const newData = [...data];
                              newData[i].imagesBefore.splice(index, 1);
                              setData(newData);
                              setTotalBefore(totalBefore.map((item, id) => id === i ? item - 1 : item));
                            }
                          }}
                        >
                          {t('component.button.delete')}
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 2 }}
                    onClick={() => {
                      const newData = [...data];
                      newData[i].imagesBefore.push(null);
                      setData(newData);
                      setTotalBefore(totalBefore.map((item, id) => id === i ? item + 1 : item));
                    }}
                  >
                    {t('component.button.addImageBefore')}
                  </Button>


                </AccordionDetails>
              </Accordion>
            ))}
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs="auto">
                <Button variant="contained" color="primary" sx={{ mt: 5 }} onClick={(e) => {
                  setTotalData(prev => prev + 1)
                  setData(prev => [...prev, { imagesBefore: [] }])
                  setTotalBefore(prev => [...prev, 1])
                }}>
                  {t('component.button.add')}
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button variant="contained" color="success" sx={{ mt: 5 }} type="submit">
                  {t('component.button.submit')}
                </Button>
              </Grid>
            </Grid>
          </form>

        </>
      ) : (
        <Card sx={{ padding: 5 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>{t('page.smat.create')}</Typography>
          <Typography sx={{ marginTop: 5 }}>{t('component.title.chooseArea')}</Typography>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, value) => setArea(value)}
            onKeyUp={(event) => setSearchArea(event.target.value)}
            id="combo-box-demo"
            options={areas}
            sx={{ width: '100%' }}
            renderInput={(params) => {
              params.fullWidth = true;
              params.margin = "dense";
              params.required = true;
              params.placeholder = t('component.text.searchArea');
              return <TextField {...params} label={t('component.text.area')} />
            }}
          />
        </Card>
      )}
    </Container>
  );
}