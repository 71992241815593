import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import del from "../assets/img/delete.svg";

export default function ConfirmDelete({ open, onClose, handleDelete, id }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('component.button.delete')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container justifyContent="center" direction="column" alignItems="center">
            <Grid item xs={12}>
              <img src={del} alt="del" style={{ maxHeight: '30vh' }} />
            </Grid>
            <Grid item xs={12}>
              {t('component.title.deleteFormDesc')}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('component.button.cancel')}</Button>
        <Button onClick={e => handleDelete(id)} autoFocus variant="contained">
          {t('component.button.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}