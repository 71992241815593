import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import {
  Card,
  Container,
  Grid,
  Button,
  styled,
  alpha,
  InputBase,
  Chip,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";

import CreateGroupModal from "../../components/qsft/member-config/CreateGroupModal";
import CreateMemberModal from "../../components/qsft/member-config/CreateMemberModal";
import ConfirmDelete from "../../components/ConfirmDelete";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Form() {
  const { t } = useTranslation();
  const location = useLocation();
  const loc = location.pathname;
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [trueData, setTrueData] = useState([]);

  const [search, setSearch] = useState("");

  const [idDelete, setIdDelete] = useState(null);
  const [idUpd, setIdUpd] = useState(null);
  const [idLeader, setIdLeader] = useState(null);
  const [idMember, setIdMember] = useState(null);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMember, setOpenMember] = useState(false);

  const fetchData = async () => {
    try {
      const result = await axios.get(`/admin/qsft/member-config`);
      setTrueData(result.data.data);
      setData((prev) => result.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search === "") return setData(trueData);

    const dataSearch = [];

    trueData.forEach((val) => {
      if (val.user.name.toLowerCase().includes(search.toLowerCase())) {
        dataSearch.push(val);

        if (val.level === 2) {
          const parent = trueData.find((val2) => val2.id === val?.parentId);
          if (parent && !dataSearch.includes(parent)) dataSearch.push(parent);

          const children = trueData.filter((val3) => val3.parentId === val.id);
          children.forEach((val4) => {
            if (!dataSearch.includes(val4)) dataSearch.push(val4);
          });
        }

        if (val.level === 3) {
          const parent = trueData.find((val2) => val2.id === val?.parentId);
          if (parent && !dataSearch.includes(parent)) dataSearch.push(parent);

          const parent2 = trueData.find((val3) => val3.id === parent?.parentId);
          if (parent2 && !dataSearch.includes(parent2)) dataSearch.push(parent2);
        }
      }
    });
    setData(dataSearch);
  }, [search]);

  const handleClose = () => {
    setOpen(false);
    setOpenMember(false);

    setIdUpd(null);
    setIdLeader(null);

    fetchData();
  };

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setIdDelete(id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIdDelete(null);
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`/admin/qsft/member-config/${id}`);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      fetchData();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
    setOpenDelete(false);
    setIdDelete(null);
  };

  const handleOpenUpd = (id) => {
    setIdUpd(id);
    setOpen(true);
  };

  const handleOpenMemberUpd = (id, leaderId) => {
    setIdLeader(leaderId);
    setIdMember(id);
    setOpenMember(true);
  };

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    getSubmenu();
  }, [axios, loc]);

  if (loading) return <Loading />;

  return (
    <Container>
      <ConfirmDelete open={openDelete} onClose={handleCloseDelete} id={idDelete} handleDelete={handleDelete} />
      <CreateGroupModal open={open} onClose={handleClose} id={idUpd} />
      <CreateMemberModal open={openMember} onClose={handleClose} id={idMember} idLeader={idLeader} />
      <h1>{title}</h1>
      <Card sx={{ padding: 1, margin: 1 }}>
        <Grid container justifyContent={"space-between"} sx={{ padding: 2 }}>
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <FontAwesomeIcon icon={"fa-solid fa-magnifying-glass"} />
              </SearchIconWrapper>
              <StyledInputBase
                value={search}
                onChange={(e) => handleSearch(e)}
                placeholder={`${t("component.text.search")}...`}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={(e) => setOpen(true)} sx={{ margin: 1 }}>
              <FontAwesomeIcon icon={"fa-solid fa-plus"} /> {t("component.button.addGroup")}
            </Button>
            <Link to="/qsft/member-config/import">
              <Button variant="contained" color="warning" sx={{ margin: 1 }}>
                {t("component.button.import")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Card>
      <Grid container justifyContent={"center"}>
        {data
          .filter((val) => val?.level === 2)
          .map((val, index) => (
            <Grid item xs={12} key={index}>
              <Card sx={{ padding: 3, margin: 1 }}>
                <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ padding: 2 }}>
                  <Grid item xs={8} textAlign={"start"}>
                    <Chip label={t("component.text.leader")} />
                    <Typography variant="h6" color="primary">
                      {val?.user?.name} | {val?.user?.position} - {val?.user?.department?.name}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                      {t("component.text.manager")} - {data.find((child) => child.id === val.parentId)?.user?.name} |{" "}
                      {data.find((child) => child.id === val.parentId)?.user?.email}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button sx={{ marginRight: 1 }} variant="contained" size="small" color="success" onClick={(e) => handleOpenUpd(val.id)}>
                      {t("component.button.update")}
                    </Button>
                    <Button sx={{ marginRight: 1 }} variant="contained" color="danger" size="small" onClick={(e) => handleOpenDelete(val.id)}>
                      {t("component.button.delete")}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={(e) => {
                        setOpenMember(true);
                        setIdLeader(val.userId);
                      }}
                    >
                      {t("component.button.addMember")}
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table sx={{ width: "100%" }} size="large" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">{t("component.text.name")}</TableCell>
                        <TableCell align="center">{t("component.text.department")}</TableCell>
                        <TableCell align="center">{t("component.text.position")}</TableCell>
                        <TableCell align="center">{t("component.text.email")}</TableCell>
                        <TableCell align="center">{t("component.text.action")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.filter((child) => child.parentId === val.id).length === 0 && (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            {t("component.text.noData")}
                          </TableCell>
                        </TableRow>
                      )}
                      {data
                        .filter((child) => child.parentId === val.id)
                        .map((row) => (
                          <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell align="center" component="th" scope="row">
                              {row.user.name}
                            </TableCell>
                            <TableCell align="center">{row?.user?.department?.name}</TableCell>
                            <TableCell align="center">{row?.user?.position}</TableCell>
                            <TableCell align="center">{row.user.email}</TableCell>
                            <TableCell align="center">
                              <Grid container spacing={1}>
                                <Grid item xs>
                                  <Button size="small" variant="contained" color="warning" onClick={() => handleOpenMemberUpd(row.id, val.userId)}>
                                    {t("component.button.update")}
                                  </Button>
                                </Grid>
                                <Grid item xs>
                                  <Button size="small" variant="contained" color="danger" onClick={() => handleOpenDelete(row.id)}>
                                    {t("component.button.delete")}
                                  </Button>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
