import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";
import Noncompliance from "../../components/project-audit/form/Noncompliance";

import SubmitForm from "../../components/SubmitForm";
import Appreciate from "../../components/Appreciate";

export default function FormDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [open, setOpen] = useState(false);

  const [openSubmit, setOpenSubmit] = useState(false);
  const [openAppreciate, setOpenAppreciate] = useState(false);

  const [subcategories, setSubcategories] = useState([]);
  const [searchSubcategories, setSearchSubcategories] = useState("");

  const [areas, setAreas] = useState([]);
  const [search, setSearch] = useState("");

  const [subcategory, setSubcategory] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");

  const [area, setArea] = useState("");
  const [areaName, setAreaName] = useState("");

  const [contractor, setContractor] = useState("");
  const [datetime, setDatetime] = useState(new Date());
  const [site, setSite] = useState("");

  const [answer, setAnswer] = useState({});
  const [totalYes, setTotalYes] = useState(0);
  const [totalNo, setTotalNo] = useState(0);
  const [questionId, setQuestionId] = useState("");
  const [actions, setActions] = useState({});
  const [edit, setEdit] = useState(false);

  const fetchData = async () => {
    try {
      setLoadingData(true);
      const res = await axios.get(`/user/project-audit/form/${subcategory}`);
      setData(res.data.data);
      setLoadingData(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
      navigate("/project-audit/form");
    }
  };

  const fetchArea = async () => {
    try {
      const res = await axios.get(`/master/area${search ? `?search=${search}` : ""}`);
      setAreas(
        res.data.data.results.map((area) => ({
          value: area.id,
          label: `${area.name}`,
        }))
      );
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const fetchSubcategories = async () => {
    try {
      const res = await axios.get(`/admin/project-subcategory?search=${searchSubcategories}&limit=100`);
      setSubcategories(
        res.data.data.results.map((subcategory) => ({
          value: subcategory.id,
          label: `${subcategory.name}`,
        }))
      );
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleSelect = (val) => {
    setAreaName(val.label);
    setArea(val.value);
  };

  const handleSelectSubcategory = (val) => {
    setSubcategoryName(val.label);
    setSubcategory(val.value);
  };

  useEffect(() => {
    document.title = `Form Detail - ${process.env.REACT_APP_NAME}`;
    fetchArea();
    fetchSubcategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (subcategory && area) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [subcategory, area]);

  const handleSearch = (val) => {
    setSearch((prev) => val);
    fetchArea();
  };

  const handleSearchSubcategories = (val) => {
    setSearchSubcategories((prev) => val);
    fetchSubcategories();
  };

  const handleChange = (id) => (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "answer":
        const newAnswer = answer;
        newAnswer[id] = value;
        setAnswer(newAnswer);
        if (value === "1") {
          const newActions = actions;
          newActions[id] = "";
          setActions(newActions);
        }
        let yes = 0;
        let no = 0;
        Object.values(answer).forEach((val) => {
          if (val === "1") {
            yes++;
          } else if (val === "0") {
            no++;
          }
        });
        setTotalYes(yes);
        setTotalNo(no);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = (data) => {
    const newActions = actions;
    newActions[data.questionId] = data;
    setActions(newActions);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      let acts = actions;
      for (let key in actions) {
        if (acts[key] === "") {
          delete acts[key];
        } else {
          acts[key].ref = "project";
          acts[key].key = parseInt(subcategory);
          acts[key].findingId = actions[key].finding.value;
          acts[key].priorityId = actions[key].priority.value;
        }
      }

      let actsArr = Object.values(acts);
      const sendData = {
        areaId: parseInt(area),
        subcategoryId: parseInt(subcategory),
        contractor,
        datetime: Math.floor(new Date(datetime).getTime() / 1000),
        site,
        answer: JSON.stringify(answer),
        actions: actsArr,
      };

      const res = await axios.post("/user/project-audit/form", sendData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setOpenSubmit(false);
      setOpenAppreciate(true);
    } catch (err) {
      console.log(err);
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
    setLoading(false);
  };

  const handleOpenSubmit = () => {
    setOpenSubmit(true);
  };

  const handleCloseAppreciate = () => {
    setOpenAppreciate(false);
    setArea("");
    setSubcategory("");
    navigate("/project-audit/form");
  };

  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  if (loading) return <Loading />;

  return (
    <>
      <Appreciate open={openAppreciate} onClose={handleCloseAppreciate} />
      <SubmitForm handleSubmit={handleSubmit} open={openSubmit} onClose={handleCloseSubmit} loading={loading} />
      <Noncompliance open={open} onClose={handleClose} handleAdd={handleAdd} questionId={questionId} edit={edit} />
      <Container>
        <Card sx={{ padding: 5 }}>
          {area && subcategory && !loadingData ? (
            <>
              <Typography variant="h5">Focused Checklsit #{data.order}</Typography>
              <Typography variant="h3">
                <strong>{data.name}</strong>
              </Typography>
              <Typography>{areaName}</Typography>
              <Grid container sx={{ marginTop: 8 }} spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    autoFocus
                    fullWidth
                    name="contractor"
                    label={t("component.text.contractor")}
                    value={contractor}
                    onChange={(e) => setContractor(e.target.value)}
                    type="text"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimePicker
                    label={t("component.text.dateTime")}
                    value={datetime ?? new Date()}
                    name="datetime"
                    ampm={false}
                    inputFormat="DD/MM/YYYY HH:mm"
                    onChange={(newValue) => {
                      setDatetime(newValue);
                    }}
                    renderInput={(params) => {
                      params.fullWidth = true;
                      params.margin = "dense";
                      params.required = true;
                      return <TextField {...params} />;
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    autoFocus
                    fullWidth
                    name="site"
                    label={t("component.text.site")}
                    value={site}
                    onChange={(e) => setSite(e.target.value)}
                    type="text"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    autoFocus
                    fullWidth
                    name="auditor"
                    label={t("component.text.auditor")}
                    value={JSON.parse(localStorage.getItem("user")).name}
                    type="text"
                    disabled
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Typography variant="body1">{t("component.title.projectAuditDesc")}</Typography>
              <Divider sx={{ marginTop: 2 }} />

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ width: "5%" }}>
                      No.
                    </TableCell>
                    <TableCell align="center">{t("component.text.question")}</TableCell>
                    <TableCell sx={{ width: "30%" }} align="center">
                      {t("component.text.answer")}
                    </TableCell>
                    <TableCell sx={{ width: "15%" }} align="center">
                      {t("component.text.action")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.projectQuestion.map((question, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>{localStorage.getItem("language") === "en" ? question.nameEn : question.nameId}</TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="answer" onChange={handleChange(question.id)} required>
                            <FormControlLabel value="1" control={<Radio />} label={t("component.text.yes")} />
                            <FormControlLabel value="0" control={<Radio />} label={t("component.text.no")} />
                            <FormControlLabel value=" " control={<Radio />} label={"N/A"} />
                          </RadioGroup>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        {answer[question.id] === "0" &&
                          (!actions[question.id] ? (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                setOpen(true);
                                setQuestionId(question.id);
                                setEdit(null);
                              }}
                            >
                              {t("component.button.addAction")}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="warning"
                              size="small"
                              onClick={() => {
                                setOpen(true);
                                setEdit(actions[question.id]);
                              }}
                            >
                              {t("component.button.editAction")}
                            </Button>
                          ))}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right" colSpan={2}>
                      Total
                    </TableCell>
                    <TableCell align="center">
                      <Grid container>
                        <Grid item xs={6}>
                          {totalYes}
                        </Grid>
                        <Grid item xs={6}>
                          {totalNo}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {totalNo > 0 && (
                <div style={{ marginTop: 20 }}>
                  <Typography color="danger">{t("component.title.nonComplianceAction")}</Typography>
                </div>
              )}
              <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleOpenSubmit}>
                {t("component.button.submit")}
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h5">Focused Checklsit</Typography>
              <Typography sx={{ marginTop: 5 }}>{t("component.title.chooseArea")}</Typography>
              <Autocomplete
                onChange={(event, value) => handleSelect(value)}
                onKeyUp={(event) => handleSearch(event.target.value)}
                id="combo-box-demo"
                options={areas}
                sx={{ width: "100%" }}
                renderInput={(params) => {
                  params.fullWidth = true;
                  params.margin = "dense";
                  params.required = true;
                  params.placeholder = t("component.text.searchArea");
                  return <TextField {...params} label={t("component.text.area")} />;
                }}
              />
              <Typography sx={{ marginTop: 5 }}>{t("component.title.chooseChecklist")}</Typography>
              <Autocomplete
                onChange={(event, value) => handleSelectSubcategory(value)}
                onKeyUp={(event) => handleSearchSubcategories(event.target.value)}
                id="combo-box-demo"
                options={subcategories}
                sx={{ width: "100%" }}
                renderInput={(params) => {
                  params.fullWidth = true;
                  params.margin = "dense";
                  params.required = true;
                  params.placeholder = t("component.text.searchChecklist");
                  return <TextField {...params} label={t("component.text.checklist")} />;
                }}
              />
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
