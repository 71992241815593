import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Container,
  Grid,
  Button,
  Typography,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";
import ConfirmDelete from "../../components/ConfirmDelete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Form() {
  const { t } = useTranslation();
  const { id, topicId } = useParams();
  const navigate = useNavigate();
  const route = useLocation().pathname;
  const isLeader = JSON.parse(localStorage.getItem("user")).roles.find((role) => role.roleId === "LEAD") ? true : false;
  const [viewOnly, setViewOnly] = useState(route.includes("detail") || !isLeader);

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(true);

  const [topic, setTopic] = useState("");
  const [topics, setTopics] = useState([]);

  const [members, setMembers] = useState([]);

  const [photo, setPhoto] = useState(null);
  const [photoName, setPhotoName] = useState("");

  const [attachment, setAttachment] = useState(null);
  const [attachmentName, setAttachmentName] = useState("");

  const [notes, setNotes] = useState("");
  const [participants, setParticipants] = useState([]);
  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem("user")).id);

  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const [search, setSearch] = useState("");

  const [planAttachment, setPlanAttachment] = useState(null);

  const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "align"];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, false] }],
      ["bold", "italic", "underline", "strike", "blockquote", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ script: "sub" }, { script: "super" }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const fetchMaster = async () => {
    try {
      const res = await axios.get(`/admin/qsft/master-plan?active=1&${search ? `?search=${search}` : ""}`);
      const data = res.data.data;
      setTopics(data.results);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const fetchTopic = async () => {
    try {
      const res = await axios.get(`/admin/qsft/master-plan/${topicId}`);
      const data = res.data.data;
      setTopic({
        label: data.topic + " (" + t(`month.${new Date(data.startDate * 1000).getMonth() + 1}`) + " " + new Date(data.startDate * 1000).getFullYear() + ")",
        value: data.id,
      });
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const fetchMember = async (user) => {
    try {
      const res = await axios.get(`/admin/qsft/member-config/leaders/${user}`);
      const data = res.data.data;
      setMembers(data);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(`/admin/qsft/audit/${id}`);
      const data = res.data.data;
      setTopic({
        label:
          data.topic.topic +
          " (" +
          t(`month.${new Date(data.topic.startDate * 1000).getMonth() + 1}`) +
          " " +
          new Date(data.topic.startDate * 1000).getFullYear() +
          ")",
        value: data.topic.id,
      });
      if (data.createdBy !== JSON.parse(localStorage.getItem("user")).id) setViewOnly(true);
      setUserId(data.createdBy);
      fetchMember(data.createdBy);
      setPlanAttachment(data.topic.attachment);
      setNotes(data.mom);
      setParticipants(data.participants);
      setAttachment(data.momFile);
      setPhoto(data.momImage);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`/admin/qsft/master-plan/${id}`);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      fetchData();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
    setOpenDelete(false);
    setIdDelete(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIdDelete(null);
  };

  const handleSelect = (val) => {
    setTopic((prev) => val);
    if (val) {
      const selected = topics.find((topic) => topic.id === val.value);
      setPlanAttachment(selected.attachment);
    } else {
      setPlanAttachment(null);
    }
  };

  const handleSearch = (val) => {
    setSearch((prev) => val);
    fetchMaster();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      topicId: topic.value,
      notes: notes,
      photo: photo,
      attachment: attachment,
      participants: participants,
    };

    try {
      const res = id ? await axios.put(`/admin/qsft/audit/${id}`, data) : await axios.post(`/admin/qsft/audit`, data);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setTopic("");
      setNotes("");
      setPhoto(null);
      setPhotoName("");
      setAttachment(null);
      setAttachmentName("");
      setParticipants([]);
      fetchMaster();

      navigate("/qsft/audit");
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchMaster();
    if (!viewOnly) {
      fetchMember(userId);
    }
    if (id) fetchData();
    if (topicId) fetchTopic();

    // eslint-disable-next-line
  }, []);

  const uploadAttachment = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios.post("/media", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setAttachment(res.data.data.url);
      setAttachmentName(file.name);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const uploadPhoto = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios.post("/media", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setPhoto(res.data.data.url);
      setPhotoName(file.name);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleChangeParticipant = (e, id) => {
    const value = e.target.value === "1" ? true : false;
    const newParticipants = members.map((item) => (item.user.id === id ? { id, presence: value } : { id }));
    setParticipants(newParticipants);
  };

  if (loading) return <Loading />;

  return (
    <Container>
      <ConfirmDelete open={openDelete} onClose={handleCloseDelete} id={idDelete} handleDelete={handleDelete} />
      <Card sx={{ padding: 5 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {viewOnly ? t("page.qsft.view") : id ? t("page.qsft.edit") : t("page.qsft.create")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={planAttachment ? 10 : 12}>
              <Autocomplete
                value={topic}
                disabled={viewOnly}
                onChange={(event, value) => handleSelect(value)}
                onKeyUp={(event) => handleSearch(event.target.value)}
                id="combo-box-demo"
                options={topics.map((topic) => ({
                  label:
                    topic.topic +
                    " (" +
                    t(`month.${new Date(topic.startDate * 1000).getMonth() + 1}`) +
                    " " +
                    new Date(topic.startDate * 1000).getFullYear() +
                    ")",
                  value: topic.id,
                }))}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label={t("component.text.topic")} />}
              />
            </Grid>
            {planAttachment && (
              <Grid item xs={2}>
                <a href={`${process.env.REACT_APP_API_URL}/${planAttachment}`} target="_blank" rel="noreferrer">
                  <Button variant="contained" color="primary" size="small" sx={{ marginRight: 1 }}>
                    {t("component.button.viewDocument")}
                  </Button>
                </a>
              </Grid>
            )}
          </Grid>
          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
            {t("component.text.mom")}
          </Typography>
          <ReactQuill theme="snow" value={notes} onChange={setNotes} formats={formats} modules={modules} readOnly={viewOnly} />

          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} lg={5}>
              <Grid container justifyContent="center" direction="row" alignItems="center" spacing={1}>
                <Grid item xs={attachment && !viewOnly ? 7 : attachment ? 10 : 12}>
                  <TextField autoFocus disabled margin="dense" label={t("component.text.attachmentDetail")} type="text" fullWidth value={attachmentName} />
                </Grid>
                {attachment && (
                  <Grid item xs={2}>
                    <Button variant="contained" component="label">
                      <a href={`${process.env.REACT_APP_API_URL}${attachment}`} target="_blank" rel="noreferrer" style={{ color: "white" }}>
                        <FontAwesomeIcon icon={"fa-solid fa-download"} />
                      </a>
                    </Button>
                  </Grid>
                )}
                {!viewOnly && (
                  <Grid item xs={3}>
                    <Button variant="contained" component="label">
                      {t("component.button.upload")}
                      <input
                        hidden
                        type="file"
                        name="image"
                        accept=".doc,.docx,application/msword,application/pdf,image/jpeg,image/jpg,image/png,.txt"
                        onChange={(e) => {
                          uploadAttachment(e.target.files[0]);
                        }}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Grid container justifyContent="center" direction="row" alignItems="center" spacing={1}>
                <Grid item xs={photo && !viewOnly ? 7 : attachment ? 10 : 12}>
                  <TextField
                    disabled
                    autoFocus
                    margin="dense"
                    label={t("component.text.evidenceMeeting")}
                    type="text"
                    fullWidth
                    value={photoName}
                    required={id ? false : true}
                  />
                </Grid>
                {photo && (
                  <Grid item xs={2}>
                    <Button variant="contained" component="label">
                      <a href={`${process.env.REACT_APP_API_URL}${photo}`} target="_blank" rel="noreferrer" style={{ color: "white" }}>
                        <FontAwesomeIcon icon={"fa-solid fa-download"} />
                      </a>
                    </Button>
                  </Grid>
                )}
                {!viewOnly && (
                  <Grid item xs={3}>
                    <Button variant="contained" component="label">
                      {t("component.button.upload")}
                      <input
                        hidden
                        type="file"
                        name="image"
                        accept="image/jpeg,image/jpg,image/png"
                        onChange={(e) => {
                          uploadPhoto(e.target.files[0]);
                        }}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent={"center"} spacing={2} direction="row" alignItems="center" sx={{ marginTop: 5 }}>
            <Grid item>
              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                {t("component.text.participant")}
              </Typography>
            </Grid>
          </Grid>

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("component.text.name")}</TableCell>
                <TableCell align="center">{t("component.text.presence")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!members?.length && (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    {t("component.text.noData")}
                  </TableCell>
                </TableRow>
              )}
              {members.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item.user.name}</TableCell>
                  <TableCell align="center">
                    {viewOnly ? (
                      participants.find((participant) => participant.userId === item.user.id)?.presence ? (
                        <FontAwesomeIcon icon={"fa-solid fa-check"} style={{ color: "green" }} />
                      ) : (
                        <FontAwesomeIcon icon={"fa-solid fa-times"} style={{ color: "red" }} />
                      )
                    ) : (
                      <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => handleChangeParticipant(e, item.user.id)}
                          >
                            <FormControlLabel value="1" control={<Radio />} label={t("component.text.yes")} />
                            <FormControlLabel value="0" control={<Radio />} label={t("component.text.no")} />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Grid container justifyContent={"space-between"} spacing={2} sx={{ marginTop: 10 }}>
            <Grid item>
              <Link to={`/qsft/audit`}>
                <Button color="primary">{t("component.button.cancel")}</Button>
              </Link>
            </Grid>
            <Grid item>
              {!viewOnly && (
                <Button type="submit" color="primary" variant="contained">
                  {id ? t("component.button.update") : t("component.button.create")}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Card>
    </Container>
  );
}
