import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";


export default function UpdateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [type, setType] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descId, setDescId] = useState("");

  useEffect(() => {

    const fetchData = async () => {
      try {
        const res = await axios.get(`/master/finding/${id}`);
        const data = res.data.data;
        setType(data.type);
        setDescEn(data.descriptionEn);
        setDescId(data.descriptionId);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "type":
        setType(value);
        break;
      case "descEn":
        setDescEn(value);
        break;
      case "descId":
        setDescId(value);
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { type, descriptionEn: descEn, descriptionId: descId };

    try {
      const res = await axios.put(`/master/finding/${id}`, data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setType("");
      setDescEn("");
      setDescId("");
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.editMenu')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('component.title.editMenuDesc')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="type"
            label={t('component.text.category')}
            type="text"
            fullWidth
            value={type}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="descEn"
            label={`${t('component.text.description')} (EN)`}
            type="text"
            multiline
            rows={3}
            fullWidth
            value={descEn}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="descId"
            label={`${t('component.text.description')} (ID)`}
            type="text"
            multiline
            rows={3}
            fullWidth
            value={descId}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('component.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}