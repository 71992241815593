import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import { Box, Divider, Toolbar, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAxiosPrivate from "../hooks/useAxiosPrivate";

const drawerWidth = 240;

export default function Sidebar() {
  const axios = useAxiosPrivate();
  const location = useLocation();
  const [submenu, setSubmenu] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loc = location.pathname.split('/')[1];
        const result = await axios.get(`/user/submenu/${loc}`);
        setSubmenu(result.data.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [location]);

  if (loading) return null;
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <Typography variant="h6" sx={{ margin: 1 }}>
          {submenu[0].menu.name}
        </Typography>
        <Divider />
        <List>
          {submenu.map((sub, index) => (
            <Link to={sub.url} key={index}>
              <ListItem key={sub.id} disablePadding>
                <ListItemButton selected={sub.url === location.pathname}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={sub.icon} />
                  </ListItemIcon>
                  <ListItemText primary={sub.name} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
    </Drawer>
  )
}