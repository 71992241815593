import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";

export default function Noncompliance({ open, onClose, handleAdd, questionId, edit }) {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();

  const [findings, setFindings] = useState([]);
  const [search, setSearch] = useState('');
  const [totalBefore, setTotalBefore] = useState(1);
  const [totalAfter, setTotalAfter] = useState(1);
  const [loading, setLoading] = useState(false);

  const priorities = [
    { value: 1, label: t('component.text.low') },
    { value: 2, label: t('component.text.medium') },
    { value: 3, label: t('component.text.high') },
  ];

  const [actionDone, setActionDone] = useState('');
  const [finding, setFinding] = useState(null);
  const [description, setDescription] = useState('');
  const [solution, setSolution] = useState('');
  const [priority, setPriority] = useState(null);

  const [imagesBefore, setImagesBefore] = useState([]);
  const [imageNamesBefore, setImageNamesBefore] = useState(['', '', '']);
  const [imagesAfter, setImagesAfter] = useState([]);
  const [imageNamesAfter, setImageNamesAfter] = useState(['', '', '']);

  const fetchFinding = async () => {
    try {
      const res = await axios.get(`/master/finding?search=${search}`);
      setFindings(res.data.data.results.map(item => ({
        value: item.id,
        label: item.type
      })));
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetchFinding();
      if (edit) {
        setActionDone(edit.actionDone);
        setFinding(edit.finding);
        setDescription(edit.description);
        setSolution(edit.solution);
        setPriority(edit.priority);
        setTotalBefore(edit.imagesBefore.length);
        setTotalAfter(edit.imagesAfter.length);
        setImagesBefore(edit.imagesBefore);
        setImagesAfter(edit.imagesAfter);
        setImageNamesAfter(edit.imagesAfter.map(item => item.name));
        setImageNamesBefore(edit.imagesBefore.map(item => item.name));
      }
    }
    // eslint-disable-next-line
  }, [open, search, edit])

  const handleClose = () => {
    setActionDone('');
    setFinding(null);
    setDescription('');
    setSolution('');
    setPriority(null);
    setImagesBefore([]);
    setImagesAfter([]);
    setImageNamesBefore(['', '', '']);
    setImageNamesAfter(['', '', '']);
    setTotalBefore(1);
    setTotalAfter(1);

    onClose();
  }

  const submit = (e) => {
    e.preventDefault();
    const data = {
      finding,
      description,
      solution,
      priority,
      imagesBefore,
      imagesAfter,
      questionId,
      actionDone,
    }
    handleAdd(data);
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{edit ? t('component.title.editAction') : t('component.title.createAction')}</DialogTitle>
      <form onSubmit={submit}>
        {!loading && (
          <DialogContent>
            <DialogContentText>
              {t('component.title.createNewActionDesc')}
            </DialogContentText>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, value) => setFinding(value)}
              onKeyUp={(event) => setSearch(event.target.value)}
              id="combo-box-demo"
              value={finding}
              options={findings}
              sx={{ width: '100%' }}
              renderInput={(params) => {
                params.fullWidth = true;
                params.margin = "dense";
                params.required = true;
                return <TextField {...params} label={t('component.text.finding')} />
              }}
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              multiline
              rows={3}
              name="description"
              label={t("component.text.description")}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              required
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              multiline
              rows={3}
              name="solution"
              label={t("component.text.solutionSuggested")}
              value={solution}
              onChange={(e) => setSolution(e.target.value)}
              type="text"
              required
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              multiline
              rows={3}
              name="actionDone"
              label={t("component.text.actionDone")}
              value={actionDone}
              onChange={(e) => setActionDone(e.target.value)}
              type="text"
              required
            />
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, value) => setPriority(value)}
              value={priority}
              id="combo-box-demo"
              options={priorities}
              sx={{ width: '100%' }}
              renderInput={(params) => {
                params.fullWidth = true;
                params.margin = "dense";
                params.required = true;
                return <TextField {...params} label={t('component.text.priority')} />
              }}
            />
            {Array.from(Array(totalBefore).keys()).map((item, index) => (
              <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2} key={index}>
                <Grid item xs={8}>
                  <TextField
                    margin="dense"
                    autoFocus
                    fullWidth
                    name="image"
                    label={t("component.text.imageBefore")}
                    value={imageNamesBefore[index]}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                  >
                    {t('component.button.upload')}
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => {
                        const newImagesBefore = [...imagesBefore];
                        newImagesBefore[index] = e.target.files[0];
                        setImagesBefore(newImagesBefore);

                        const newImageNamesBefore = [...imageNamesBefore];
                        newImageNamesBefore[index] = e.target.files[0].name;
                        setImageNamesBefore(newImageNamesBefore);
                      }}
                    />
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    component="label"
                    color="danger"
                    fullWidth
                    onClick={() => {
                      if (totalBefore > 1) {
                        setTotalBefore(totalBefore - 1);
                        const newImagesBefore = [...imagesBefore];
                        newImagesBefore.splice(index, 1);
                        setImagesBefore(newImagesBefore);
                      }
                    }}
                  >
                    {t('component.button.delete')}
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mb: 2 }}
              onClick={() => setTotalBefore((prev) => {
                return prev > 2 ? prev : prev + 1
              })}
            >
              {t('component.button.addImageBefore')}
            </Button>
            {Array.from(Array(totalAfter).keys()).map((item, index) => (
              <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2} key={index}>
                <Grid item xs={8}>
                  <TextField
                    margin="dense"
                    autoFocus
                    fullWidth
                    name="image"
                    label={t("component.text.imageAfter")}
                    value={imageNamesAfter[index]}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                  >
                    {t('component.button.upload')}
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => {
                        const newImagesAfter = [...imagesAfter];
                        newImagesAfter[index] = e.target.files[0];
                        setImagesAfter(newImagesAfter);

                        const newImageNamesAfter = [...imageNamesAfter];
                        newImageNamesAfter[index] = e.target.files[0].name;
                        setImageNamesAfter(newImageNamesAfter);
                      }}
                    />
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    component="label"
                    color="danger"
                    fullWidth
                    onClick={() => {
                      if (totalAfter > 1) {
                        setTotalAfter(totalAfter - 1);
                        const newImagesAfter = [...imagesAfter];
                        newImagesAfter.splice(index, 1);
                        setImagesAfter(newImagesAfter);
                      }
                    }}
                  >
                    {t('component.button.delete')}
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setTotalAfter((prev) => {
                return prev > 2 ? prev : prev + 1
              })}
            >
              {t('component.button.addImageAfter')}
            </Button>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('component.button.cancel')}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {edit ? t('component.button.update') : t('component.button.create')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}