import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { Card, Container, Grid, Button, Table, TableHead, TableRow, TableCell, styled, alpha, InputBase, TablePagination, TableBody } from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";

import CreateModal from "../../components/qsft/master-plan/CreateModal";
import ConfirmDelete from "../../components/ConfirmDelete";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function MasterPlan() {
  const { t } = useTranslation();
  const location = useLocation();
  const loc = location.pathname;
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [idUpd, setIdUpd] = useState(null);

  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    try {
      const result = await axios.get(`/admin/qsft/master-plan?page=${page}&limit=${rowsPerPage}&search=${search}`);
      setData((prev) => result.data.data.results);
      setTotal(result.data.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
    setIdUpd(null);
    fetchData();
  };

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setIdDelete(id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIdDelete(null);
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`/admin/qsft/master-plan/${id}`);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      fetchData();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
    setOpenDelete(false);
    setIdDelete(null);
  };

  const handleOpenUpd = (id) => {
    setIdUpd(id);
    setOpen(true);
  };

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    getSubmenu();
  }, [axios, loc]);

  if (loading) return <Loading />;

  return (
    <Container>
      <ConfirmDelete open={openDelete} onClose={handleCloseDelete} id={idDelete} handleDelete={handleDelete} />
      <CreateModal open={open} onClose={handleClose} id={idUpd} />
      <h1>{title}</h1>
      <Card sx={{ padding: 5 }}>
        <Grid container justifyContent={"space-between"} sx={{ padding: 2 }}>
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <FontAwesomeIcon icon={"fa-solid fa-magnifying-glass"} />
              </SearchIconWrapper>
              <StyledInputBase
                value={search}
                onChange={(e) => handleSearch(e)}
                placeholder={`${t("component.text.search")}...`}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Grid>
          <Grid item>
            {!JSON.parse(localStorage.getItem("user")).roles.find((role) => role.roleId === "INT" || role.roleId === "EXT") && (
              <Button variant="contained" color="primary" onClick={(e) => setOpen(true)}>
                <FontAwesomeIcon icon={"fa-solid fa-plus"} /> {t("component.button.add")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("component.text.topic")}</TableCell>
              <TableCell align="center">{t("component.text.period")}</TableCell>
              <TableCell align="center">{t("component.text.attachment")}</TableCell>
              <TableCell align="center">{t("component.text.createdBy")}</TableCell>
              <TableCell align="center">{t("component.text.updatedBy")}</TableCell>
              <TableCell align="center">{t("component.text.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  {t("component.text.noData")}
                </TableCell>
              </TableRow>
            )}
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">{item.topic}</TableCell>
                <TableCell align="center">
                  {t(`month.${new Date(item.startDate * 1000).getMonth() + 1}`) + " " + new Date(item.startDate * 1000).getFullYear()}
                </TableCell>
                <TableCell align="center">
                  {item.attachment === null ? (
                    t("component.text.noFile")
                  ) : (
                    <a href={`${process.env.REACT_APP_API_URL}/${item.attachment}`} target="_blank" rel="noreferrer">
                      <Button variant="contained" color="primary" size="small" sx={{ marginRight: 1 }}>
                        {t("component.text.open")}
                      </Button>
                    </a>
                  )}
                </TableCell>
                <TableCell align="center">{item.created.name}</TableCell>
                <TableCell align="center">{item.updated?.name}</TableCell>
                <TableCell>
                  <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item>
                      <Button size="small" variant="contained" color="warning" onClick={() => handleOpenUpd(item.id)}>
                        {t("component.button.update")}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button size="small" variant="contained" color="danger" onClick={() => handleOpenDelete(item.id)}>
                        {t("component.button.delete")}
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
