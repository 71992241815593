import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";


export default function CreateModal({ open, onClose, idForm, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const minValue = 1;
  const maxValue = 100;
  const [nameId, setNameId] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [order, setOrder] = useState(minValue);

  const fetchData = async () => {
    try {
      const res = await axios.get(`/admin/dca-category/${id}`);
      setNameId(res.data.data.nameId);
      setNameEn(res.data.data.nameEn);
      setOrder(res.data.data.order);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'nameId':
        setNameId(value);
        break;
      case 'nameEn':
        setNameEn(value);
        break;
      case 'order':
        setOrder(prev => Math.min(Math.max(e.target.value, minValue), maxValue));
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { nameId, nameEn, order, dcaFormId: idForm };

    try {
      const res = id ? await axios.put(`/admin/dca-category/${id}`, data) : await axios.post(`/admin/dca-category/`, data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setNameId('');
      setNameEn('');
      setOrder(minValue);
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {id ? t('component.title.editSection') : t('component.title.addSection')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {id ? t('component.title.editSectionDesc') : t('component.title.addSectionDesc')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="nameEn"
            label={`${t('component.text.name')} (EN)`}
            type="text"
            fullWidth
            value={nameEn}
            onChange={handleChange}
            required
          />
          <TextField
            autoFocus
            margin="dense"
            name="nameId"
            label={`${t('component.text.name')} (ID)`}
            type="text"
            fullWidth
            value={nameId}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="order"
            label={t('component.text.order')}
            type="number"
            min="1"
            max="99"
            fullWidth
            value={order}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {id ? t('component.button.save') : t('component.button.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}