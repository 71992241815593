import { useState } from 'react';
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import CreateQuestion from "./category/CreateQuestion";
import ConfirmDelete from '../../ConfirmDelete';

export default function CategoryList({ categories, onClick, onClose, handleIdUpd }) {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [idQuestion, setIdQuestion] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
  const [idDeleteQuestion, setIdDeleteQuestion] = useState(null);

  const handleDelete = async (id) => {
    try {
      const del = await axios.delete(`/admin/dca-category/${id}`);
      del.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
    setOpenDelete(false);
    setIdDelete(null);
  }

  const handleDeleteQuestion = async (id) => {
    try {
      const del = await axios.delete(`/admin/dca-question/${id}`);
      del.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
    setOpenDeleteQuestion(false);
    setIdDeleteQuestion(null);
  }

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setIdDelete(id);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIdDelete(null);
  }

  const handleOpenDeleteQuestion = (id) => {
    setOpenDeleteQuestion(true);
    setIdDeleteQuestion(id);
  }

  const handleCloseDeleteQuestion = () => {
    setOpenDeleteQuestion(false);
    setIdDeleteQuestion(null);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setId(null);
    setIdQuestion(null);
    onClose();
  }

  return (
    <>
      <ConfirmDelete open={openDelete} onClose={handleCloseDelete} id={idDelete} handleDelete={handleDelete} />
      <ConfirmDelete open={openDeleteQuestion} onClose={handleCloseDeleteQuestion} id={idDeleteQuestion} handleDelete={handleDeleteQuestion} />
      <CreateQuestion open={open} onClose={handleClose} idCategory={id} id={idQuestion} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <strong>{t("component.text.section")}</strong>
            </TableCell>
            <TableCell rowSpan={2} align="center" sx={{ width: 'auto' }}>
              <strong>{t("component.text.order")}</strong>
            </TableCell>
            <TableCell rowSpan={2} align="center" sx={{ width: 'auto' }}>
              <strong>{t("component.text.action")}</strong>
            </TableCell>
            <TableCell rowSpan={2} align="center">
              <strong>{t("component.text.question")}</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">
              <strong>EN</strong>
            </TableCell>
            <TableCell align="center">
              <strong>ID</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!categories.length && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                {t("component.text.noData")}
              </TableCell>
            </TableRow>
          )}
          {categories && categories.map((category, index) => (
            <TableRow>
              <TableCell>{category.nameEn}</TableCell>
              <TableCell>{category.nameId}</TableCell>
              <TableCell align="center">{category.order}</TableCell>
              <TableCell>
                <Grid container spacing={1} direction="column" alignItems="center">
                  <Grid item>
                    <Button variant="contained" onClick={
                      e => {
                        handleIdUpd(category.id);
                        onClick(e);
                      }}>
                      {t('component.button.update')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" size="small" color="danger" onClick={e => handleOpenDelete(category.id)}>
                      {t("component.button.delete")}
                    </Button>
                  </Grid>
                  <Grid item>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button variant="contained" size="small" color="success" onClick={e => {
                      setId(category.id);
                      handleOpen();
                    }}>
                      {t("component.button.addQuestion")}
                    </Button>
                  </Grid>
                </Grid>
                <Table>
                  <TableHead>
                    <TableCell>EN</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell sx={{ width: '20%' }}>
                      {t("component.text.action")}
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {!category.dcaFormQuestion.length && (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          {t("component.text.noData")}
                        </TableCell>
                      </TableRow>
                    )}
                    {category.dcaFormQuestion.map(question => (
                      <TableRow>
                        <TableCell>{question.nameEn}</TableCell>
                        <TableCell>{question.nameId}</TableCell>
                        <TableCell>
                          <Grid container spacing={1} direction="column" alignItems="center">
                            <Grid item>
                              <Button variant="contained" size="small" color="primary" onClick={e => {
                                setId(category.id);
                                setIdQuestion(question.id);
                                handleOpen();
                              }}>
                                {t("component.button.update")}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button variant="contained" size="small" color="danger" onClick={e => handleOpenDeleteQuestion(question.id)}>
                                {t("component.button.delete")}
                              </Button>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table >
    </>
  );
}


