import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import { Card, Container, Grid, Button, Table, TableHead, TableRow, TableCell, TablePagination, TableBody, Chip } from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import Loading from "../../Loading";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Audit() {
  const { t } = useTranslation();
  const location = useLocation();
  const loc = location.pathname;
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const result = await axios.get(`/user/dca-audit/schedule?page=0&limit=12`);
      setData((prev) => result.data.data.results);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    getSubmenu();
  }, [axios, loc]);

  if (loading) return <Loading />;

  return (
    <Container>
      <h1>{title}</h1>
      <Card sx={{ padding: 5 }}>
        <Grid container justifyContent={"space-between"} sx={{ padding: 2 }}></Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("component.text.date")}</TableCell>
              <TableCell>{t("component.text.checklist")}</TableCell>
              <TableCell align="center">{t("component.text.status")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  {t("component.text.noData")}
                </TableCell>
              </TableRow>
            )}
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{t(`month.${new Date(item.date * 1000).getMonth() + 1}`) + " " + new Date(item.date * 1000).getFullYear()}</TableCell>
                <TableCell>{item.dcaForm.name}</TableCell>
                <TableCell align="center">
                  {item.status === 1 ? (
                    <FontAwesomeIcon icon={"fa-solid fa-check"} style={{ color: "green" }} />
                  ) : (
                    <FontAwesomeIcon icon={"fa-solid fa-times"} style={{ color: "red" }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </Container>
  );
}
