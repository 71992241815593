import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loading from "../Loading";

export default function Area({ area, setArea }) {
  const axios = useAxiosPrivate();
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [search, setSearch] = useState('');

  const fetchArea = async () => {
    try {
      const result = await axios.get(`/master/area?search=${search}`);
      setAreas(prev => result.data.data.results);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchArea();
    // eslint-disable-next-line
  }, [search])

  if (loading) return <Loading />

  return (
    <FormControl fullWidth>
      <InputLabel id="area">Area</InputLabel>
      <Select
        labelId="area"
        id="area"
        label="Area"
        defaultValue=""
        fullWidth
        value={area}
        onChange={e => setArea(e.target.value)}
      >
        {areas && areas.map((item, index) => (
          <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}