import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";


export default function UpdateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [area, setArea] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [totalPic, setTotalPic] = useState(1);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const fetchUser = async () => {
    const res = await axios.get(`/master/user${search ? `?search=${search}` : ''}`);
    setUsers(res.data.data.results.map(user => ({
      label: user.name,
      value: user.id,
    })));
  }

  useEffect(() => {

    const fetchData = async () => {
      try {
        await fetchUser();
        const res = await axios.get(`/master/area/${id}`);
        const data = res.data.data;
        setArea(data.name);
        setTotalPic(data.pic.length);
        setSelectedUsers(data.pic.map(user => ({
          label: user.name,
          value: user.id,
        })));
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])

  const handleSearch = (val) => {
    setSearch(prev => val);
    fetchUser();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name: area, pic: Array.from(new Set(selectedUsers.map(user => user.value))) };

    try {
      const res = await axios.put(`/master/area/${id}`, data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setSearch('');
      setSelectedUsers([]);
      setUsers([]);
      setArea(null);
      onClose();
      setLoading(true);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.editArea')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('component.title.editAreaDesc')}
        </DialogContentText>
        {loading ? <div>Loading...</div> : (
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              margin="dense"
              name="area"
              label={t('component.text.area')}
              type="text"
              fullWidth
              value={area}
              onChange={e => setArea(e.target.value)}
            />
            {
              Array.from(Array(totalPic).keys()).map((_, i) => (
                <div style={{ marginTop: 10 }} key={i}>
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                      <Autocomplete
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        value={selectedUsers[i]}
                        onChange={(event, value) => {
                          setSelectedUsers(prev => {
                            prev[i] = value;
                            return prev;
                          });
                        }}
                        onKeyUp={(event) => handleSearch(event.target.value)}
                        id="combo-box-demo"
                        options={users}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} label={t('component.text.pic')} />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        component="label"
                        color="danger"
                        fullWidth
                        onClick={() => {
                          if (totalPic > 1) {
                            setTotalPic(totalPic - 1);
                            const newSelectedUsers = [...selectedUsers];
                            newSelectedUsers.splice(i, 1);
                            setSelectedUsers(newSelectedUsers);
                          }
                        }}
                      >
                        {t('component.button.delete')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))
            }
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => {
                setTotalPic((prev) => {
                  return prev + 1
                })
              }}
            >
              {t('component.button.addPic')}
            </Button>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('component.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}