import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { DatePicker } from "@mui/x-date-pickers";

export default function CreateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [expDate, setExpDate] = useState(new Date());

  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/user/external-audit/${id}`);
        const data = res.data.data;
        setName(data.name);
        setLastUpdate(new Date(data.lastUpdate * 1000));
        setExpDate(new Date(data.expDate * 1000));
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    setName('');
    setFile(null);
    setFileName('');
    setLastUpdate(new Date());
    setExpDate(new Date());

    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id, open])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        name,
        file,
        lastUpdate: Math.floor(new Date(lastUpdate).getTime() / 1000),
        expDate: Math.floor(new Date(expDate).getTime() / 1000),
      }
      const res = id ?
        await axios.put(`/user/external-audit/${id}`, data, {
          headers: {
            'Content-Type': file ? 'multipart/form-data' : 'application/json'
          }
        })
        :
        await axios.post("/user/external-audit", data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setName('');
      setFile(null);
      setFileName('');
      setLastUpdate(new Date());
      setExpDate(new Date());
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{id ? t('component.title.editExternalAudit') : t('component.title.createExternalAudit')}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            {id ? t('component.title.editExternalAuditDesc') : t('component.title.createExternalAuditDesc')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label={t('component.text.name')}
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <DatePicker
            label={t('component.text.lastUpdate')}
            value={lastUpdate}
            name="lastUpdate"
            inputFormat="DD/MM/YYYY"
            onChange={(newValue) => {
              setLastUpdate(newValue);
            }}
            renderInput={(params) => {
              params.fullWidth = true;
              params.margin = "dense";
              params.required = true;
              return <TextField {...params} />
            }}
          />
          <DatePicker
            label={t('component.text.expDate')}
            value={expDate}
            name="expDate"
            inputFormat="DD/MM/YYYY"
            onChange={(newValue) => {
              setExpDate(newValue);
            }}
            renderInput={(params) => {
              params.fullWidth = true;
              params.margin = "dense";
              params.required = true;
              return <TextField {...params} />
            }}
          />
          <Grid container justifyContent="center" direction="row" alignItems="center" spacing={1}>
            <Grid item xs={10}>
              <TextField
                autoFocus
                margin="dense"
                label={t('component.text.file')}
                type="text"
                fullWidth
                value={fileName}
                required={id ? false : true}
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" component="label">
                {t('component.button.upload')}
                <input hidden type="file" name="image" accept=".doc,.docx,application/msword,application/pdf,image/jpeg,image/jpg,image/png" onChange={
                  (e) => {
                    setFile(e.target.files[0]);
                    setFileName(e.target.files[0].name);
                  }
                } />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('component.button.cancel')}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {id ? t('component.button.update') : t('component.button.create')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}