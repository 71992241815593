import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { Autocomplete, Card, Container, TextField, Typography } from "@mui/material";
import List from "../../components/dca-audit/view-form/List";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";

export default function FormDetail() {
  const { idParams } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [id, setId] = useState(idParams ?? null);
  const [search, setSearch] = useState("");
  const [checklist, setChecklist] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(`/user/dca-audit/form/${id}`);
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      navigate(-1);
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const getSubmenu = async () => {
    try {
      const loc = location.pathname;
      const res = await axios.post(`/user/submenu-url/`, { url: loc });
      setTitle(res.data.data.name);
      document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const fetchChecklist = async () => {
    try {
      const res = await axios.get(`/admin/dca-form?search=${search}&active=1`);
      setChecklist(res.data.data.results.map((item) => ({ value: item.id, label: item.name })));
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchChecklist();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      fetchData();
    } else {
      getSubmenu();
    }
    // eslint-disable-next-line
  }, [id]);

  const handleSelectChecklist = (val) => {
    setId(val.value);
  };

  const handleSearchChecklist = (val) => {
    setSearch(val);
    fetchChecklist();
  };

  if (loading) return <Loading />;

  return (
    <>
      <Container>
        <h1>{title}</h1>
        <Card sx={{ padding: 5 }}>
          {id && data ? (
            <>
              <Typography variant="h4">{data.name}</Typography>
              <img
                src={`${process.env.REACT_APP_API_URL}/${data.imageHeader}`}
                alt={data.name}
                style={{
                  maxWidth: "25vw",
                  maxHeight: "25vw",
                  margin: 30,
                }}
              />
              <List categories={data.dcaFormCategory} schedule={data.dcaFormSchedule[0]} id={id} />
            </>
          ) : (
            <>
              <Typography sx={{ marginTop: 1 }}>{t("component.title.chooseChecklist")}</Typography>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(event, value) => handleSelectChecklist(value)}
                onKeyUp={(event) => handleSearchChecklist(event.target.value)}
                id="combo-box-demo"
                options={checklist}
                sx={{ width: "100%" }}
                renderInput={(params) => {
                  params.fullWidth = true;
                  params.margin = "dense";
                  params.required = true;
                  params.placeholder = t("component.text.searchChecklist");
                  return <TextField {...params} label={t("component.text.checklist")} />;
                }}
              />
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
