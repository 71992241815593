import axios from '../services/axios';
// import useAuth from './useAuth';

const useRefreshToken = () => {
  // const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.post('/auth/generate-token', {
      withCredentials: true
    });
    // setAuth(prev => {
    //   return { ...prev, token: response.data.data.token }
    // });
    return response.data.data;
  }
  return refresh;
};

export default useRefreshToken;
