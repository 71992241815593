import { Card, Container } from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import UserTable from "../../components/admin/user-management/UserTable";
import Loading from "../../components/Loading";

export default function UserManagement() {
  const location = useLocation();
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const loc = location.pathname;
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    getSubmenu();
    // eslint-disable-next-line
  }, [])

  if (loading) return <Loading />

  return (
    <Container>
      <h1>{title}</h1>
      <Card>
        <UserTable />
      </Card>
    </Container>
  )
}