import { createTheme, experimental_sx as sx } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0080C0",
      transparent: "rgba(0, 128, 192, 0.5)",
      contrastText: "#F5F5F5",
    },
    secondary: {
      main: "#2C3152",
      contrastText: "#0080C0",
    },
    background: {
      main: "#F5F5F5",
      contrastText: "#0080C0",
    },
    danger: {
      main: "#DB3A34",
      contrastText: "#F0F3F5",
    },
    success: {
      main: "#018236",
      contrastText: "#F0F3F5",
    },
    text: {
      primary: "#000000",
      secondary: "#0080C0",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            "&.Mui-selected-hover": {
              backgroundColor: "primary.main",
              color: "primary.contrastText",
            },
            "&.Mui-selected-child": {
              color: "primary.contrastText",
            },
          },
          "&.Mui-selected-hover": {
            backgroundColor: "primary.main",
            color: "primary.contrastText",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "unset",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fontSize: 20,
          },
        },
      },
    },
  },
});

export default theme;
