import { useState } from 'react';
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddUserRole from './AddUserRole';
import ConfirmDelete from '../../ConfirmDelete';


export default function DenseTable({ users, id }) {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpenDelete = (id) => {
    setIdDelete(id);
    setOpenDelete(true);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIdDelete(null);
  }

  const handleDelete = async (userId) => {
    try {
      const res = await axios.post(`/master/unassign-role/`, {
        userId,
        roleId: id,
      });
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      window.location.reload();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      }
      );
    }
    setOpenDelete(false);
    setIdDelete(null);
  }

  return (
    <div>
      <ConfirmDelete open={openDelete} onClose={handleCloseDelete} id={idDelete} handleDelete={handleDelete} />
      <AddUserRole open={open} onClose={handleClose} id={id} />
      <Grid container justifyContent={"space-between"} sx={{ padding: 2 }}>
        <Grid item>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleOpen}>
            {t('component.button.add')}
          </Button>
        </Grid>
      </Grid>
      <Table sx={{ minWidth: 600 }} size="large" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="center">Role</TableCell>
            <TableCell align="center">{t('component.text.action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                {t('component.text.noData')}
              </TableCell>
            </TableRow>
          )}
          {users && users.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell >{row.email}</TableCell>
              <TableCell align="center">
                <Grid container >
                  <Grid item xs>
                    <Button size="small" variant="contained" color="danger" onClick={() => handleOpenDelete(row.id)}>
                      {t('component.button.delete')}
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
