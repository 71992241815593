import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, ImageList, ImageListItem, TextField, Typography } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";

import Loading from "../../Loading";

export default function NoncomplianceView({ open, onClose, id }) {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const priorities = [
    { value: 1, label: t('component.text.low') },
    { value: 2, label: t('component.text.medium') },
    { value: 3, label: t('component.text.high') },
  ];

  const fetchData = async () => {
    try {
      const res = await axios.get(`/user/project-audit/non-compliance/${id}`);
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
      setLoading(false);
      onClose();
    }
  }

  const handleClose = () => {
    setData({});
    onClose();
  }

  useEffect(() => {
    if (open && id) {
      setLoading(true);
      fetchData();
    }
    // eslint-disable-next-line
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.viewAction')}</DialogTitle>
      <form>
        {loading && <Loading />}
        {!loading && Object.keys(data).length !== 0 && (
          <DialogContent>
            <Grid container>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" >
                  {t('component.text.imageBefore')}
                </Typography>
                {data?.findingDashboardDetail?.map((item, index) => {
                  return item.imageBefore ? (
                    <img
                      key={item.imageBefore}
                      src={`${process.env.REACT_APP_API_URL}/${item.imageBefore}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${process.env.REACT_APP_API_URL}/${item.imageBefore}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.imageBefore}
                      style={{
                        maxHeight: 164,
                      }}
                      loading="lazy"
                    />
                  ) : null;
                })}
              </Grid>
              <Grid item xs={12} textAlign="center">
                {data?.findingDashboardDetail?.filter((item) => item.imageAfter).length > 0 && (
                  <>
                    <Typography variant="h6" >
                      {t('component.text.imageAfter')}
                    </Typography>
                    {data?.findingDashboardDetail?.map((item, index) => {
                      return item.imageAfter ? (
                        <img
                          key={item.imageAfter}
                          src={`${process.env.REACT_APP_API_URL}/${item.imageAfter}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${process.env.REACT_APP_API_URL}/${item.imageAfter}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.imageAfter}
                          style={{
                            maxHeight: 164,
                          }}
                          loading="lazy"
                        />
                      ) : null;
                    })}
                  </>
                )}
              </Grid>
            </Grid>
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              name="finding"
              label={t('component.text.finding')}
              value={data.finding.type}
              type="text"
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              multiline
              rows={3}
              name="description"
              label={t("component.text.description")}
              value={data.description}
              type="text"
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              multiline
              rows={3}
              name="solution"
              label={t("component.text.solutionSuggested")}
              value={data.solution}
              type="text"
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              multiline
              rows={3}
              name="actionDone"
              label={t("component.text.actionDone")}
              value={data.actionDone}
              type="text"
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              name="priority"
              label={t("component.text.priority")}
              value={priorities.find(p => p.value === data.priority).label}
              type="text"
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('component.button.close')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}