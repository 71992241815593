import { useEffect, useState } from 'react';
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { alpha, Button, Grid, InputBase, styled, TablePagination, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Chip } from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateModal from './CreateModal';
import UpdateModal from './UpdateModal';
import ConfirmDelete from '../../ConfirmDelete';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function AreaTable() {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openUpd, setOpenUpd] = useState(false);
  const [total, setTotal] = useState(0);
  const [idUpd, setIdUpd] = useState(null);
  const [search, setSearch] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const fetchData = async () => {
    try {
      const result = await axios.get(`/master/area?page=${page}&limit=${rowsPerPage}&search=${search}`);
      setData(prev => new Set(result.data.data.results));
      setTotal(result.data.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, rowsPerPage, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    fetchData();
  }

  const handleCloseUpd = () => {
    setOpenUpd(false);
    setIdUpd(null);
    fetchData();
  }

  const handleOpenDelete = (id) => {
    setIdDelete(id);
    setOpenDelete(true);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
  }

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`/master/area/${id}`);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      fetchData();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      }
      );
    }
    setOpenDelete(false);
    setIdDelete(null);
  }

  const handleUpdate = (id) => {
    setIdUpd(id);
    setOpenUpd(true);
  }

  return (
    <div>
      <ConfirmDelete open={openDelete} onClose={handleCloseDelete} handleDelete={handleDelete} id={idDelete} />
      <CreateModal open={open} onClose={handleClose} />
      <UpdateModal open={openUpd} onClose={handleCloseUpd} id={idUpd} />
      <TableContainer component={Paper}>
        <Grid container justifyContent={"space-between"} sx={{ padding: 2 }}>
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <FontAwesomeIcon icon={"fa-solid fa-magnifying-glass"} />
              </SearchIconWrapper>
              <StyledInputBase
                value={search}
                onChange={handleSearch}
                placeholder={`${t('component.text.search')}...`}
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" onClick={handleOpen}>
              {t('component.button.create')}
            </Button>
          </Grid>
        </Grid>
        <Table sx={{ width: '100%' }} size="large" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{t('component.text.area')}</TableCell>
              <TableCell align="center">{t('component.text.pic')}</TableCell>
              <TableCell align="center">{t('component.text.action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && data.size === 0 && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {t('component.text.noData')}
                </TableCell>
              </TableRow>
            )}
            {!loading && data.size > 0 && Array.from(data).map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell >
                  {row.pic.map(pic => (
                    <Chip label={pic.name} key={pic.id} />
                  ))}
                </TableCell>
                <TableCell align="center">
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Button size="small" variant="contained" color="success" onClick={() => handleUpdate(row.id)}>
                        {t('component.button.update')}
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Button size="small" variant="contained" color="danger" onClick={() => handleOpenDelete(row.id)}>
                        {t('component.button.delete')}
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}
