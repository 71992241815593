import { BottomNavigation, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <BottomNavigation
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: "0px -1px 5px 0px rgba(0,0,0,0.2)",
        zIndex: 1,
        alignItems: "center",
        paddingRight: '30px',
        paddingLeft: '260px',
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography color="primary" variant="subtitle2">
            Copyright © 2022 Digital Safety Audit. All rights reserved.
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <Link to="/about">
                <Typography color="primary" variant="subtitle2">
                  About
                </Typography>
              </Link>
            </Grid>
            <Grid item xs="auto">
              <Link to="/contact">
                <Typography color="primary" variant="subtitle2">
                  Contact
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BottomNavigation>
  );
}