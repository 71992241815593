import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function CreateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");

  const [selectedLeader, setSelectedLeader] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);

  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    const res = await axios.get(`/master/user${search ? `?search=${search}` : ""}`);
    setUsers(
      res.data.data.results.map((user) => ({
        label: user.name,
        value: user.id,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/admin/qsft/member-config/${id}`);
        const data = res.data.data;
        setSelectedLeader({ label: data.user.name, value: data.user.id });
        setSelectedManager({ label: data.parent.user.name, value: data.parent.user.id });
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    if (id) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [id, open]);

  const handleSelect = (val) => {
    setSelectedLeader((prev) => val);
  };

  const handleSelectManager = (val) => {
    setSelectedManager((prev) => val);
  };

  const handleSearch = (val) => {
    setSearch((prev) => val);
    fetchUser();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        userId: selectedLeader?.value,
        managerId: selectedManager?.value,
      };
      const res = id
        ? await axios.put(`/admin/qsft/member-config/${id}`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
        : await axios.post("/admin/qsft/member-config", data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });

      setSelectedLeader(null);
      setSelectedManager(null);

      handleClose();
    } catch (err) {
      console.log(err);
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleClose = () => {
    setSelectedLeader(null);
    setSelectedManager(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{id ? t("component.title.editGroup") : t("component.title.addGroup")}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>{id ? t("component.title.editGroupDesc") : t("component.title.addNewGroupDesc")}</DialogContentText>
          <Autocomplete
            onChange={(event, value) => handleSelect(value)}
            onKeyUp={(event) => handleSearch(event.target.value)}
            id="combo-box-demo"
            value={selectedLeader}
            options={users}
            sx={{ width: "100%", paddingTop: 2 }}
            renderInput={(params) => <TextField {...params} required label={t("component.text.leader")} />}
          />
          <Autocomplete
            onChange={(event, value) => handleSelectManager(value)}
            onKeyUp={(event) => handleSearch(event.target.value)}
            id="combo-box-demo"
            value={selectedManager}
            options={users}
            sx={{ width: "100%", paddingTop: 2 }}
            renderInput={(params) => <TextField {...params} required label={t("component.text.manager")} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("component.button.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {id ? t("component.button.update") : t("component.button.create")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
