import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { Button, Card, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CreateQuestion from "../../components/project-audit/master-form/question/CreateModal";
import Loading from "../../components/Loading";
import ConfirmDelete from '../../components/ConfirmDelete';


export default function FormDetail() {
  const { id } = useParams();
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [idUpd, setIdUpd] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const fetchData = async () => {
    try {
      const res = await axios.get(`/admin/project-subcategory/${id}`);
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }


  useEffect(() => {
    document.title = `Form Detail - ${process.env.REACT_APP_NAME}`;
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setIdUpd(null);
    fetchData();
  }

  const handleIdUpd = (id) => {
    setIdUpd(id);
    setOpen(true);
  }

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setIdDelete(id);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIdDelete(null);
  }

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`/admin/project-question/${id}`);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      fetchData();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      }
      );
    }
    setOpenDelete(false);
    setIdDelete(null);
  }


  if (loading) return <Loading />

  return (
    <>
      <ConfirmDelete open={openDelete} onClose={handleCloseDelete} id={idDelete} handleDelete={handleDelete} />
      <CreateQuestion open={open} onClose={handleClose} idForm={id} id={idUpd} />
      <h1>Form Detail</h1>
      <Container sx={{ width: '100vw' }}>
        <Card sx={{ padding: 5 }}>
          <Typography variant="h4">{data.name}</Typography>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" onClick={handleOpen}>
                {t('component.button.addQuestion')}
              </Button>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>{t('component.text.question')}</TableCell>
                <TableCell align="center" rowSpan={2}>{t('component.text.action')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">EN</TableCell>
                <TableCell align="center">ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!data.projectQuestion.length && (
                <TableRow>
                  <TableCell colSpan={3} align="center">{t('component.text.noData')}</TableCell>
                </TableRow>
              )}
              {data.projectQuestion.map((question, index) => (
                <TableRow key={index}>
                  <TableCell>{question.nameEn}</TableCell>
                  <TableCell>{question.nameId}</TableCell>
                  <TableCell>
                    <Grid container spacing={1}>
                      <Grid item xs>
                        <Button size="small" variant="contained" color="success" onClick={() => handleIdUpd(question.id)}>
                          {t('component.button.update')}
                        </Button>
                      </Grid>
                      <Grid item xs>
                        <Button size="small" variant="contained" color="danger" onClick={() => handleOpenDelete(question.id)}>
                          {t('component.button.delete')}
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Container>
    </>
  )
}