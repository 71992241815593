import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import id from "./locales/id.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    id: {
      translation: id
    }
  },
  lng: localStorage.getItem('language') || 'en',
  fallbackLng: 'en',
  // debug: true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true
  }
});

export default i18n;