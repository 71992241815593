import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import {
  Card,
  Container,
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  styled,
  alpha,
  InputBase,
  TablePagination,
  TableBody,
  Chip,
} from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmDelete from "../../components/ConfirmDelete";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Audit() {
  const { t } = useTranslation();
  const location = useLocation();
  const loc = location.pathname;
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");

  const fetchData = async () => {
    try {
      const result = await axios.get(`/user/dca-audit/schedule?page=${page}&limit=${rowsPerPage}&search=${search}`);
      setData((prev) => result.data.data.results);
      setTotal(result.data.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    getSubmenu();
  }, [axios, loc]);

  if (loading) return <Loading />;

  return (
    <Container>
      <h1>{title}</h1>
      <Card sx={{ padding: 5 }}>
        <Grid container justifyContent={"space-between"} sx={{ padding: 2 }}>
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <FontAwesomeIcon icon={"fa-solid fa-magnifying-glass"} />
              </SearchIconWrapper>
              <StyledInputBase
                value={search}
                onChange={(e) => handleSearch(e)}
                placeholder={`${t("component.text.search")}...`}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("component.text.checklist")}</TableCell>
              <TableCell align="center">{t("component.text.pic")}</TableCell>
              <TableCell align="center">{t("component.text.area")}</TableCell>
              <TableCell align="center">{t("component.text.date")}</TableCell>
              <TableCell align="center">{t("component.text.status")}</TableCell>
              <TableCell align="center">{t("component.text.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  {t("component.text.noData")}
                </TableCell>
              </TableRow>
            )}
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.dcaForm.name}</TableCell>
                <TableCell>{item.user.name}</TableCell>
                <TableCell>{item?.dcaFormArea?.area?.name}</TableCell>
                <TableCell align="center">{t(`month.${new Date(item.date * 1000).getMonth() + 1}`) + " " + new Date(item.date * 1000).getFullYear()}</TableCell>
                <TableCell align="center">
                  {item.status === 1 ? <Chip label={t("component.text.closed")} color="success" /> : <Chip label={t("component.text.open")} color="danger" />}
                </TableCell>
                <TableCell align="center">
                  <Grid container spacing={1} justifyContent="center" alignItems="center">
                    {item.status === 1 ? (
                      <Grid item>
                        <Link to={`/dca-audit/list/${item.dcaFormAnswerId}`}>
                          <Button size="small" variant="contained" color="success">
                            {t("component.button.detail")}
                          </Button>
                        </Link>
                      </Grid>
                    ) : (
                      new Date(item.date * 1000) < new Date() && (
                        <Grid item>
                          <Link to={`/dca-audit/form/${item.dcaForm.id}`}>
                            <Button size="small" variant="contained" color="warning">
                              {t("component.button.fillForm")}
                            </Button>
                          </Link>
                        </Grid>
                      )
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
