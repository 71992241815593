import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { Button, Card, Container, Grid, Typography } from "@mui/material";
import CategoryList from "../../components/dca-audit/master-form/CategoryList";
import CreateCategory from "../../components/dca-audit/master-form/category/CreateCategory";
import Loading from "../../components/Loading";

export default function FormDetail() {
  const { id } = useParams();
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [idUpd, setIdUpd] = useState(null);

  const fetchData = async () => {
    try {
      const res = await axios.get(`/admin/dca-form/${id}`);
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    document.title = `Form Detail - ${process.env.REACT_APP_NAME}`;
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchData();
  };

  const handleIdUpd = (id) => {
    setIdUpd(id);
  };

  if (loading) return <Loading />;

  return (
    <>
      <CreateCategory open={open} onClose={handleClose} idForm={id} id={idUpd} />
      <h1>Form Detail</h1>
      <Container sx={{ width: "100vw" }}>
        <Card sx={{ padding: 5 }}>
          <Typography variant="h4">{data.name}</Typography>
          <img
            src={`${process.env.REACT_APP_API_URL}/${data.imageHeader}`}
            alt={data.name}
            style={{
              maxWidth: "30vw",
              maxHeight: "30vw",
              margin: 30,
            }}
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" onClick={handleOpen}>
                {t("component.button.addSection")}
              </Button>
            </Grid>
          </Grid>
          <CategoryList categories={data.dcaFormCategory} onClose={handleClose} onClick={handleOpen} handleIdUpd={handleIdUpd} />
        </Card>
      </Container>
    </>
  );
}
