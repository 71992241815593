import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Loading from "../../Loading";


export default function UpdateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const minValue = 1;
  const maxValue = 100;
  const [name, setName] = useState('');
  const [order, setOrder] = useState(minValue);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const category = await axios.get(`/admin/project-category/`);
        const dataCategory = category.data.data.results;
        setCategory(dataCategory);

        const res = await axios.get(`/admin/project-subcategory/${id}`);
        const data = res.data.data;
        setName(data.name);
        setOrder(data.order);
        setCategoryId(data.projectCategoryId);
        setIsActive(data.isActive === 1 ? true : false);
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'categoryId':
        setCategoryId(value);
        break;
      case 'order':
        setOrder(prev => Math.min(Math.max(e.target.value, minValue), maxValue));
        break;
      case 'isActive':
        setIsActive(prev => !prev);
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, order, projectCategoryId: categoryId, isActive };

    try {
      const res = await axios.put(`/admin/project-subcategory/${id}`, data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setName('');
      setOrder(minValue);
      setIsActive(false);
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.editForm')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('component.title.editFormDesc')}
        </DialogContentText>
        {open && loading ? <Loading /> : (
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="category">{t('component.text.category')}</InputLabel>
              <Select
                labelId="category"
                name="categoryId"
                value={categoryId}
                label={t('component.text.category')}
                onChange={handleChange}
                required
              >
                {category.map(item => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label={t('component.text.name')}
              type="text"
              fullWidth
              value={name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="order"
              label={t('component.text.order')}
              type="number"
              min="1"
              max="99"
              fullWidth
              value={order}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActive}
                  onChange={handleChange}
                  name="isActive"
                  color="primary"
                />
              }
              label={t('component.text.active')}
            />
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('component.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}