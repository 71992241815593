import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function CreateModal({ open, onClose }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [id, setId] = useState('');


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'id':
        setId(value);
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { roleName: name, roleId: id };

    try {
      const res = await axios.post("/master/role", data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setName('');
      setId('');

      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.createRole')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('component.title.createNewRoleDesc')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            name="id"
            label="ID"
            type="text"
            fullWidth
            value={id}
            onChange={handleChange}
          />
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label={t('component.text.name')}
            type="text"
            fullWidth
            value={name}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('component.button.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}