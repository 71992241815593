import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function UpdateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [departments, setDepartments] = useState([]);
  const [searchDepartment, setSearchDepartment] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [department, setDepartment] = useState(null);
  const [position, setPosition] = useState("");
  const [isActive, setIsActive] = useState(false);

  const fetchDepartment = async () => {
    const res = await axios.get(`/master/department${searchDepartment ? `?search=${searchDepartment}` : ""}`);
    setDepartments(
      res.data.data.results.map((department) => ({
        label: department.name,
        value: department.id,
      }))
    );
  };

  useEffect(() => {
    setEmployeeId("");
    const fetchData = async () => {
      try {
        const res = await axios.get(`/master/user/${id}`);

        const data = res.data.data;
        setDepartment({ label: data?.department?.name ?? "", value: data?.department?.id ?? null });
        setName(data.name);
        setEmail(data.email);
        setPosition(data.position);
        setEmployeeId(data.employeeId);
        setIsActive(data.isActive === 1 ? true : false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    if (id) fetchData();
    if (open) fetchDepartment();
    // eslint-disable-next-line
  }, [id]);

  const handleSearchDepartment = (val) => {
    setSearchDepartment((prev) => val);
    fetchDepartment();
  };

  const handleSelectDepartment = (val) => {
    setDepartment((prev) => val);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "employeeId":
        setEmployeeId(value);
        break;
      case "position":
        setPosition(value);
        break;
      case "isActive":
        setIsActive((prev) => !prev);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, email, isActive, employeeId, departmentId: department.value, position };

    try {
      const res = await axios.put(`/master/user/${id}`, data);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setName("");
      setEmail("");
      setPosition("");
      setDepartment(null);
      setEmployeeId("");
      setIsActive(false);
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t("component.title.editUser")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("component.title.editUserDesc")}</DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField autoFocus margin="dense" name="name" label={t("component.text.name")} type="text" fullWidth value={name} onChange={handleChange} />
          <TextField margin="dense" name="email" label={t("component.text.email")} type="text" fullWidth value={email} onChange={handleChange} />
          <TextField margin="dense" name="employeeId" label={t("component.text.employeeId")} type="text" fullWidth value={employeeId} onChange={handleChange} />
          <TextField margin="dense" name="position" label={t("component.text.position")} type="text" fullWidth value={position} onChange={handleChange} />
          <Autocomplete
            onChange={(event, value) => handleSelectDepartment(value)}
            onKeyUp={(event) => handleSearchDepartment(event.target.value)}
            id="combo-box-demo"
            value={department}
            options={departments}
            sx={{ width: "100%", paddingTop: 2 }}
            renderInput={(params) => <TextField {...params} name="department" label={t("component.text.department")} />}
          />
          <FormControlLabel
            control={<Checkbox checked={isActive} onChange={handleChange} name="isActive" color="primary" />}
            label={t("component.text.active")}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("component.button.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t("component.button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
