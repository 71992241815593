import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Loading from "../../Loading";


export default function CreateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const fetchUser = async () => {
    const res = await axios.get(`/master/user${search ? `?search=${search}` : ''}`);
    setUsers(res.data.data.results.map(user => ({
      label: user.name,
      value: user.id,
    })));
    setLoading(false);
  }

  useEffect(() => {
    if (open) {
      fetchUser();
    };
    // eslint-disable-next-line
  }, [open]);

  const handleSelect = (val) => {
    const idUser = val.map(user => user.value);
    setSelectedUser(prev => new Set(idUser));
  }

  const handleSearch = (val) => {
    setSearch(prev => val);
    fetchUser();
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { userId: Array.from(selectedUser), roleId: id };
    try {
      const res = await axios.post("/master/assign-role", data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setSearch('');
      setSelectedUser([]);
      setUsers([]);
      onClose();
      window.location.reload();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.assignUser')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('component.title.assignUserDesc')}
        </DialogContentText>
        {loading ? <Loading /> : (
          <form onSubmit={handleSubmit}>
            <Autocomplete
              multiple
              onChange={(event, value) => handleSelect(value)}
              onKeyUp={(event) => handleSearch(event.target.value)}
              id="combo-box-demo"
              options={users}
              sx={{ width: '100%', paddingTop: 2 }}
              renderInput={(params) => <TextField {...params} label={t('component.text.name')} />}
            />
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('component.button.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}