import { Box, Card, Container, Grid, TextField, Button, Typography, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { register, getRoles } from "../../services/auth";

import logo1 from "../../assets/img/logo1.png";
import Loading from "../../components/Loading";
import axios from "../../services/axios";

export default function Register() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);

  const [departments, setDepartments] = useState([]);
  const [searchDepartment, setSearchDepartment] = useState("");
  const [department, setDepartment] = useState(null);

  const getRoleList = async () => {
    const res = await getRoles();
    setRoles(
      res.data.data.map((item) => ({
        value: item.roleId,
        label: item.roleName,
      }))
    );
    setLoading(false);
  };

  const fetchDepartment = async () => {
    try {
      const res = await axios.get(`/master/department${searchDepartment ? `?search=${searchDepartment}` : ""}`);
      setDepartments(
        res.data.data.results.map((department) => ({
          label: department.name,
          value: department.id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    document.title = `Register - ${process.env.REACT_APP_NAME}`;

    getRoleList();
    fetchDepartment();
  }, [loading]);

  const handleSearchDepartment = (val) => {
    setSearchDepartment((prev) => val);
    fetchDepartment();
  };

  const handleSelectDepartment = (val) => {
    setDepartment((prev) => val);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const sendData = {
      name: data.get("name"),
      email: data.get("email"),
      password: data.get("password"),
      confirm_password: data.get("confirm_password"),
      employee_id: data.get("employee_id"),
      role: role.value,
      position: data.get("position"),
      department_id: department.value,
    };

    try {
      const res = await register(sendData);
      if (res.status >= 200 && res.status < 300) {
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
        navigate("/auth/login");
      } else {
        res.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) return <Loading />;

  return (
    <Container sx={{ width: "60%" }}>
      <img src={logo1} alt="logo" style={{ marginBottom: 50 }} />
      <Card>
        <Box component="form" sx={{ marginTop: 5, marginBottom: 5 }} onSubmit={onSubmit}>
          <Container>
            <Typography variant="h6" noWrap component="div" color="primary" sx={{ marginBottom: 5 }}>
              Register
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField sx={{ width: "100%" }} label="Name" name="name" color="primary" variant="outlined" autoComplete="name" required />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Employee ID"
                      name="employee_id"
                      color="primary"
                      variant="outlined"
                      autoComplete="employee-id"
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField sx={{ width: "100%" }} label="Email" name="email" color="primary" variant="outlined" autoComplete="email" required />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onChange={(event, value) => setRole(value)}
                      id="combo-box-demo"
                      options={roles}
                      sx={{ width: "100%" }}
                      renderInput={(params) => {
                        params.fullWidth = true;
                        params.required = true;
                        return <TextField {...params} label="Role" />;
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField sx={{ width: "100%" }} label="Position" name="position" color="primary" variant="outlined" autoComplete="position" required />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      onChange={(event, value) => handleSelectDepartment(value)}
                      onKeyUp={(event) => handleSearchDepartment(event.target.value)}
                      id="combo-box-demo"
                      value={department}
                      options={departments}
                      sx={{ width: "100%" }}
                      renderInput={(params) => <TextField {...params} name="department" label="Department" />}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Password"
                      name="password"
                      type="password"
                      color="primary"
                      variant="outlined"
                      autoComplete="current-password"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Confirm Password"
                      name="confirm_password"
                      type="password"
                      color="primary"
                      variant="outlined"
                      autoComplete="current-password"
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Register
                </Button>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {"Already have an account?"}
                  <Link to="/auth/login"> Login</Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Card>
    </Container>
  );
}
