import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/mui-theme";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import './firebase';
import './i18n';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fab, fas, far);

Sentry.init({
  dsn: "https://a020cf919aeb432a9da0932d6a5122db@o4503986234654720.ingest.sentry.io/4503986245795840",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      // eslint-disable-next-line
      const registration = await navigator.serviceWorker.register("./firebase-messaging-sw.js", {
        scope: "/",
      });
      // if (registration.installing) {
      //   console.log("Service worker installing");
      // } else if (registration.waiting) {
      //   console.log("Service worker installed");
      // } else if (registration.active) {
      //   console.log("Service worker active");
      // }
    } catch (error) {
      // console.error(`Registration failed with ${error}`);
    }
  }
};

registerServiceWorker();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <App />
      </LocalizationProvider>
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
