import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";


export default function CreateModal({ open, onClose, idCategory, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [nameId, setNameId] = useState('');
  const [nameEn, setNameEn] = useState('');

  const fetchData = async () => {
    try {
      const res = await axios.get(`/admin/dca-question/${id}`);
      setNameId(res.data.data.nameId);
      setNameEn(res.data.data.nameEn);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'nameId':
        setNameId(value);
        break;
      case 'nameEn':
        setNameEn(value);
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { nameId, nameEn, dcaFormCategoryId: idCategory };

    try {
      const res = id ? await axios.put(`/admin/dca-question/${id}`, data) : await axios.post(`/admin/dca-question/`, data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setNameId('');
      setNameEn('');
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {id ? t('component.title.editQuestion') : t('component.title.addQuestion')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {id ? t('component.title.editQuestionDesc') : t('component.title.addQuestionDesc')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="nameEn"
            multiline
            rows={4}
            label={`${t('component.text.question')} (EN)`}
            type="text"
            fullWidth
            value={nameEn}
            onChange={handleChange}
            required
          />
          <TextField
            autoFocus
            margin="dense"
            name="nameId"
            multiline
            rows={4}
            label={`${t('component.text.question')} (ID)`}
            type="text"
            fullWidth
            value={nameId}
            onChange={handleChange}
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {id ? t('component.button.save') : t('component.button.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}