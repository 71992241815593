import { Button, Card, Chip, Grid, Table, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loading from "../../components/Loading";
import SetToDone from "../../components/finding-list/SetToDone";

export default function Detail() {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    const res = await axios.get(`/user/report-finding/${id}`);
    setData(res.data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const handleClose = () => {
    setOpen(false);
    fetchData();
  };

  if (loading) return <Loading />;

  return (
    <div>
      <SetToDone open={open} onClose={handleClose} />
      <h1>Detail Report</h1>
      <Card sx={{ p: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            {data?.findingDashboardDetail?.filter((item) => item.imageBefore).length > 0 && (
              <>
                <Typography variant="h6">{t("component.text.imageBefore")}</Typography>
                {data?.findingDashboardDetail?.map((item, index) => {
                  return item.imageBefore ? (
                    <img
                      key={item.imageBefore}
                      src={`${process.env.REACT_APP_API_URL}/${item.imageBefore}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${process.env.REACT_APP_API_URL}/${item.imageBefore}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.imageBefore}
                      style={{
                        maxHeight: 300,
                      }}
                      loading="lazy"
                    />
                  ) : null;
                })}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {data?.findingDashboardDetail?.filter((item) => item.imageAfter).length > 0 && (
              <>
                <Typography variant="h6">{t("component.text.imageAfter")}</Typography>
                {data?.findingDashboardDetail?.map((item, index) => {
                  return item.imageAfter ? (
                    <img
                      key={item.imageAfter}
                      src={`${process.env.REACT_APP_API_URL}/${item.imageAfter}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${process.env.REACT_APP_API_URL}/${item.imageAfter}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.imageAfter}
                      style={{
                        maxHeight: 300,
                      }}
                      loading="lazy"
                    />
                  ) : null;
                })}
              </>
            )}
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("component.text.date")}</TableCell>
              <TableCell>{new Date(data.date * 1000).toLocaleDateString("id-ID")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.description")}</TableCell>
              <TableCell>{data.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.solutionSuggested")}</TableCell>
              <TableCell>{data.solution}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.area")}</TableCell>
              <TableCell>{data.areas.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.priority")}</TableCell>
              <TableCell>
                {data.priority === 1 ? t("component.text.low") : data.priority === 2 ? t("component.text.medium") : t("component.text.high")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.pic")}</TableCell>
              <TableCell>{data.areas.pic.map((item) => item.name).join(", ")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.actionDone")}</TableCell>
              <TableCell>{data.actionDone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.status")}</TableCell>
              <TableCell>
                {data.status === 1 ? (
                  <Chip label={t("component.text.closed")} color="success" />
                ) : data.status === 2 ? (
                  <Chip label={t("component.text.verification")} color="warning" />
                ) : (
                  <Chip label={t("component.text.open")} color="danger" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.closedAt")}</TableCell>
              <TableCell>{data?.closedAt ? new Date(data.closedAt * 1000).toLocaleString("id-ID") : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.closedBy")}</TableCell>
              <TableCell>{data.closedBy?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.verifiedAt")}</TableCell>
              <TableCell>{data?.verifiedAt ? new Date(data.verifiedAt * 1000).toLocaleString("id-ID") : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("component.text.verifiedBy")}</TableCell>
              <TableCell>{data.verifiedBy?.name}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {(data.areas.pic.find((item) => item.id === JSON.parse(localStorage.getItem("user")).id) ||
          JSON.parse(localStorage.getItem("user")).roles.find((role) => role.roleId === "SFT") ||
          JSON.parse(localStorage.getItem("user")).roles.find((role) => role.roleId === "SFT EXT")) &&
          data.status === 0 && (
            <Grid container justifyContent="center" sx={{ my: 5 }}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={(e) => setOpen((prev) => true)}>
                  {t("component.button.setToDone")}
                </Button>
              </Grid>
            </Grid>
          )}
      </Card>
    </div>
  );
}
