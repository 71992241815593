import { Box, Card, Container, Grid, TextField, Button, Typography, CircularProgress } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import logo1 from "../../assets/img/logo1.png";
import loginImg from "../../assets/img/login.svg";
import { login, resendOtp, verifyOtp } from "../../services/auth";

const loginImgStyle = {
  maxWidth: "500px",
  display: "block",
  marginTop: "40px",
  marginBottom: "10px",
  marginLeft: "auto",
  marginRight: "auto",
  position: "relative",
};

export default function Login() {
  const navigate = useNavigate();
  const [stepOtp, setStepOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = `Login - ${process.env.REACT_APP_NAME}`;
    document.getElementsByClassName("App-header")[0].style.backgroundColor = "#0080C0";
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData(e.target);
    const sendData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    try {
      const res = await login(sendData);
      if (res.status >= 200 && res.status < 300) {
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
        if (sendData.email.includes("sudo:") || res.data?.data?.token) {
          const { token, user } = res.data.data;

          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
          if (!user.email) {
            navigate("/profile");
          } else {
            navigate("/");
          }
        } else {
          setEmail(sendData.email);
          setStepOtp(true);
        }
      } else {
        res.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const handleChange = async (otp) => {
    setOtp(otp);
    if (otp.length === 6) {
      setIsLoading(true);
      const data = { email, otp };
      try {
        const res = await verifyOtp(data);
        if (res.status >= 200 && res.status < 300) {
          res.data.message.reverse().forEach((message) => {
            toast.success(message);
          });
          const { token, user } = res.data.data;

          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
          window.location.reload();
          navigate("/");
        } else {
          res.data.message.reverse().forEach((message) => {
            toast.error(message);
          });
        }
      } catch (err) {
        console.log(err);
      }

      setOtp("");
      setIsLoading(false);
    }
  };

  const resend = async (e) => {
    try {
      const data = { email };
      const res = await resendOtp(data);
      if (res.status >= 200 && res.status < 300) {
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
      } else {
        res.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmitOtp = async () => {};

  return (
    <Container>
      <img src={logo1} alt="logo" style={{ marginBottom: 50 }} />
      <Card>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <img src={loginImg} alt="login" className="img-fluid" style={loginImgStyle} />
          </Grid>
          <Grid item xs={6}>
            {stepOtp ? (
              <>
                <Box component="form" onSubmit={onSubmitOtp} sx={{ marginTop: 5, marginBottom: 5 }}>
                  <Container>
                    <Typography variant="h4" noWrap component="div" color="primary" sx={{ marginBottom: 1 }}>
                      OTP Code
                    </Typography>
                    <Typography variant="subtitle1" noWrap component="div" color="primary" sx={{ marginBottom: 5 }}>
                      Please enter the OTP code sent to {email}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MuiOtpInput value={otp} onChange={handleChange} length={6} TextFieldsProps={{ disabled: isLoading, size: "small" }} />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center">
                          <Grid item xs={6} textAlign="center">
                            <Typography variant="body2">
                              {"didn't receive the code?   "}
                              <Button onClick={resend}>Resend Otp</Button>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </>
            ) : (
              <Box component="form" onSubmit={onSubmit} sx={{ marginTop: 5, marginBottom: 5 }}>
                <Container>
                  <Typography variant="h6" noWrap component="div" color="primary" sx={{ marginBottom: 5 }}>
                    Login
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField sx={{ width: "100%" }} label="Employee ID" name="email" color="primary" variant="outlined" autoComplete="username" required />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Password"
                        name="password"
                        type="password"
                        color="primary"
                        variant="outlined"
                        autoComplete="current-password"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={6} textAlign="left">
                          <Typography variant="body2">
                            {"Don't have an account?"}
                            <Link to="/auth/register"> Register</Link>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography variant="body2">
                            <Link to="/auth/forgot-password">Forgot Password?</Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                        {isLoading ? <CircularProgress size={20} sx={{ marginRight: 1 }} /> : "Login"}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            )}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}
