import { useEffect } from "react";
import { axiosPrivate } from "../services/axios";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
  // const { auth } = useAuth();
  const refresh = useRefreshToken();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      config => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? token}`;
        }
        return config;
      }, (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      response => response,
      async (error) => {
        console.log(error);
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          localStorage.setItem("token", newAccessToken.token);
          localStorage.setItem("user", JSON.stringify(newAccessToken.user));
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken.token}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    }
    // eslint-disable-next-line
  }, [refresh])

  return axiosPrivate;
}

export default useAxiosPrivate;