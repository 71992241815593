import { Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import SubmitForm from "../../../components/SubmitForm";
import Appreciate from "../../../components/Appreciate";

export default function List({ categories, id, schedule }) {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const auditor = JSON.parse(localStorage.getItem("user")).name;
  const [auditee, setAuditee] = useState("");
  const [location, setLocation] = useState(schedule?.dcaFormArea?.area?.name ?? "");
  const [datetime, setDatetime] = useState(new Date());
  const [comment, setComment] = useState({});
  const [answer, setAnswer] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAppreciate, setOpenAppreciate] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (id) => (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "comment":
        const newComment = comment;
        newComment[id] = value;
        setComment(newComment);
        break;
      case "answer":
        const newAnswer = answer;
        newAnswer[id] = value;
        setAnswer(newAnswer);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { formId: id, name: auditee, location, date: Math.floor(new Date(datetime).getTime() / 1000), comment, answer };
    try {
      const res = await axios.post(`/user/dca-audit/form`, data);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setOpen(false);
      setLoading(false);
      setOpenAppreciate(true);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
      setOpen(false);
    }
  };

  const handleCloseAppreciate = () => {
    setOpenAppreciate(false);
    navigate("/dca-audit/schedule");
  };

  return (
    <div>
      <Appreciate open={openAppreciate} onClose={handleCloseAppreciate} />
      <SubmitForm handleSubmit={handleSubmit} open={open} onClose={handleClose} loading={loading} />
      <TextField autoFocus margin="dense" name="auditor" label={t("component.text.auditor")} type="text" fullWidth value={auditor} disabled required />
      <TextField
        autoFocus
        margin="dense"
        name="location"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        label={t("component.text.location")}
        type="text"
        required
        fullWidth
        disabled
      />
      <TextField
        autoFocus
        margin="dense"
        name="auditee"
        label={t("component.text.auditee")}
        type="text"
        fullWidth
        required
        value={auditee}
        onChange={(e) => setAuditee(e.target.value)}
      />
      <DateTimePicker
        label={t("component.text.dateTime")}
        value={datetime ?? new Date()}
        name="datetime"
        ampm={false}
        inputFormat="DD/MM/YYYY HH:mm"
        onChange={(newValue) => {
          setDatetime(newValue);
        }}
        renderInput={(params) => {
          params.fullWidth = true;
          params.margin = "dense";
          params.required = true;
          return <TextField {...params} />;
        }}
      />
      <Divider sx={{ marginTop: 5 }} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("component.text.section")}</TableCell>
            <TableCell>{t("component.text.question")}</TableCell>
            <TableCell>{t("component.text.answer")}</TableCell>
            <TableCell>{t("component.text.comment")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category, index) => {
            return category.dcaFormQuestion.map((question, index) => {
              return index === 0 ? (
                <TableRow key={question.id}>
                  <TableCell rowSpan={category.dcaFormQuestion.length}>
                    {localStorage.getItem("language") === "en" ? category.nameEn : category.nameId}
                  </TableCell>
                  <TableCell>{localStorage.getItem("language") === "en" ? question.nameEn : question.nameId}</TableCell>
                  <TableCell>
                    <FormControl>
                      <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="answer" onChange={handleChange(question.id)} required>
                        <FormControlLabel value="1" control={<Radio />} label={t("component.text.yes")} />
                        <FormControlLabel value="0" control={<Radio />} label={t("component.text.no")} />
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="outlined-multiline-flexible"
                      label={t("component.text.comment")}
                      multiline
                      rows={4}
                      name="comment"
                      value={comment[`${question.id}`]}
                      onInput={handleChange(question.id)}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={question.id}>
                  <TableCell>{localStorage.getItem("language") === "en" ? question.nameEn : question.nameId}</TableCell>
                  <TableCell>
                    <FormControl>
                      <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="answer" onChange={handleChange(question.id)} required>
                        <FormControlLabel value="1" control={<Radio />} label={t("component.text.yes")} />
                        <FormControlLabel value="0" control={<Radio />} label={t("component.text.no")} />
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="outlined-multiline-flexible"
                      label={t("component.text.comment")}
                      multiline
                      rows={4}
                      name="comment"
                      value={comment[`${question.id}`]}
                      onChange={handleChange(question.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
      <Button variant="contained" sx={{ marginTop: 5 }} onClick={handleOpen}>
        {t("component.text.submit")}
      </Button>
    </div>
  );
}
