import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Card, Checkbox, Chip, Container, Divider, FormControlLabel, Grid, } from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";
import UserTable from "../../components/admin/roles/UserTable";

export default function RoleDetail() {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState({});

  const fetchData = async () => {
    try {
      const res = await axios.get(`/master/role/${id}`);
      setData(res.data.data);

      const resMenu = await axios.get(`/master/all-menu`);
      setMenu(resMenu.data.data);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  useEffect(() => {
    document.title = `Role Detail - ${process.env.REACT_APP_NAME}`;
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])

  const handleChange = async (e, submenuId) => {
    const checked = e.target.checked;

    try {
      let res;
      if (checked) {
        res = await axios.post(`/master/assign-access/`, { submenuId, roleId: id });
      } else {
        res = await axios.post(`/master/unassign-access/`, { submenuId, roleId: id });
      }

      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });

      fetchData();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <h1>Role Detail - {data.roleName}</h1>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Card>
            <Container sx={{ marginBottom: 4 }}>
              <h3>{t('component.text.access')}</h3>
              <Grid container spacing={3}>
                {menu.map(menu => (
                  <Grid item xs={12} key={menu.menuId}>
                    <Divider textAlign="center" variant="middle">
                      <Chip label={menu.name} />
                    </Divider>
                    <Grid container direction="column" justifyContent={"flex-start"} alignItems={"flex-start"}>
                      {menu.submenu.map(submenu => (
                        <Grid item xs={12} key={submenu.id}>
                          <FormControlLabel control={<Checkbox defaultChecked={data.roleAccess.map(submenu => submenu.submenuId).includes(submenu.id)} onChange={(e) => handleChange(e, submenu.id)} />} label={submenu.name} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <Container sx={{ marginBottom: 4 }}>
              <h3>{t('component.text.user')}</h3>
              <UserTable users={data.users} id={id} />
            </Container>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )

}