import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function CreateModal({ open, onClose }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [forms, setForms] = useState([]);
  const [search, setSearch] = useState("");
  const [searchForm, setSearchForm] = useState("");

  const [name, setName] = useState("");
  const [selectedPic, setSelectedPic] = useState(null);
  const [selectedForm, setSelectedForm] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetchUser();
      fetchForm();
    }
    // eslint-disable-next-line
  }, [open]);

  const fetchUser = async () => {
    const res = await axios.get(`/master/user${search ? `?search=${search}` : ""}`);
    setUsers(
      res.data.data.results.map((user) => ({
        label: user.name,
        value: user.id,
      }))
    );
    setLoading(false);
  };

  const fetchForm = async () => {
    const res = await axios.get(`/admin/dca-form${searchForm ? `?search=${searchForm}` : ""}`);
    setForms(
      res.data.data.results.map((form) => ({
        label: form.name,
        value: form.id,
      }))
    );
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, pic: selectedPic.value, formId: selectedForm.map((form) => form.value) };

    try {
      const res = await axios.post("/admin/dca-area", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setName("");
      setSelectedPic(null);
      setSelectedForm([]);

      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleSelect = (val) => {
    setSelectedPic((prev) => val);
  };

  const handleSearch = (val) => {
    setSearch((prev) => val);
    fetchUser();
  };

  const handleSelectForm = (val) => {
    setSelectedForm((prev) => val);
  };

  const handleSearchForm = (val) => {
    setSearchForm((prev) => val);
    fetchForm();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t("component.title.createArea")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("component.title.createNewAreaDesc")}</DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label={t("component.text.name")}
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Autocomplete
            onChange={(event, value) => handleSelect(value)}
            onKeyUp={(event) => handleSearch(event.target.value)}
            id="combo-box-pic"
            value={selectedPic}
            options={users}
            sx={{ width: "100%", paddingTop: 2 }}
            renderInput={(params) => <TextField {...params} required label={t("component.text.pic")} />}
          />
          <Autocomplete
            onChange={(event, value) => handleSelectForm(value)}
            onKeyUp={(event) => handleSearchForm(event.target.value)}
            id="combo-box-form"
            value={selectedForm}
            options={forms}
            multiple
            sx={{ width: "100%", paddingTop: 2 }}
            renderInput={(params) => <TextField {...params} required label={t("component.text.checklist")} />}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("component.button.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t("component.button.create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
