import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Grid, Typography, Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function Notification() {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);


  useEffect(() => {
    document.title = `${t('page.notification.notification')} - ${process.env.REACT_APP_NAME}`;
    // eslint-disable-next-line
  }, [])

  const getNotification = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/user/notification?page=${page}&limit=${rowsPerPage}`);
      setTotal(prev => res.data.data.total);
      setData(prev => res.data.data.results);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleReadNotif = async (id, url) => {
    try {
      const res = await axios.put(`/user/notification/${id}`);
      if (res.status >= 200 && res.status < 300) {
        navigate(url);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {t('page.notification.notification')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} size="large" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('page.notification.notification')}</TableCell>
                  <TableCell align="center">{t('component.text.date')}</TableCell>
                  <TableCell align="center">{t('component.text.action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && data.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      {t('component.text.noData')}
                    </TableCell>
                  </TableRow>
                )}
                {!loading && data.length > 0 && data.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" >
                      <Typography color={row.isRead ? '' : 'primary'}>
                        {row.isRead ? '' : '● '} {row.title} - {row.body}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color={row.isRead ? '' : 'primary'}>
                        {new Date(row.createdAt * 1000).toLocaleString('id-ID')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={e => handleReadNotif(row.id, row.url)}>
                        {t('component.text.open')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={total}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
