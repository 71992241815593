import { Box, Card, Container, Grid, TextField, Button, Typography } from "@mui/material";
import toast from "react-hot-toast";

import logo1 from "../../assets/img/logo1.png";
import forgotPassword from "../../assets/img/forgot_password.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { forgot } from "../../services/auth";

const forgotPasswordStyle = {
  maxWidth: '450px',
  display: 'block',
  marginTop: '40px',
  marginBottom: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
}



export default function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new FormData(e.target);
    const sendData = {
      email: data.get("email"),
    }

    try {
      const res = await forgot(sendData);
      if (res.status >= 200 && res.status < 300) {
        res.data.message.reverse().forEach(message => {
          toast.success(message);
        });
        navigate("/auth/login");
      } else {
        res.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    document.title = `Forgot Password - ${process.env.REACT_APP_NAME}`;
  }, []);

  return (
    <Container>
      <img src={logo1} alt="logo" style={{ marginBottom: 50 }} />
      <Card>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <img src={forgotPassword} alt="forgot" className="img-fluid" style={forgotPasswordStyle} />
          </Grid>
          <Grid item xs={6}>
            <Box component="form" onSubmit={onSubmit} sx={{ marginTop: 5, marginBottom: 5 }}>
              <Container>
                <Typography variant="h6" noWrap component="div" color="primary">
                  Forgot Password
                </Typography>
                <Typography variant="caption" sx={{ marginBottom: 3 }} component="div">
                  We will send a link to reset your password
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField sx={{ width: '100%' }} label="Email" name="email" color="primary" variant="outlined" autoComplete="username" required />
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>{loading ? 'Loading' : 'Forgot Password'}</Button>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Grid>
        </Grid>

      </Card>
    </Container>
  )
}