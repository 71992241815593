import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";

import Loading from "../Loading";

export default function Edit({ open, onClose, edit }) {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();

  const [findings, setFindings] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingAreas, setLoadingAreas] = useState(true);

  const [areas, setAreas] = useState([]);
  const [searchArea, setSearchArea] = useState('');

  const priorities = [
    { value: 1, label: t('component.text.low') },
    { value: 2, label: t('component.text.medium') },
    { value: 3, label: t('component.text.high') },
  ];

  const statuses = [
    { value: 0, label: t('component.text.open') },
    { value: 1, label: t('component.text.closed') },
    { value: 2, label: t('component.text.verification') },
    { value: 3, label: t('component.text.rejected') },
  ]

  const [actionDone, setActionDone] = useState('');
  const [finding, setFinding] = useState(null);
  const [description, setDescription] = useState('');
  const [solution, setSolution] = useState('');
  const [priority, setPriority] = useState(null);
  const [status, setStatus] = useState(null);
  const [area, setArea] = useState(null);

  const fetchFinding = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/master/finding?search=${search}`);
      setFindings(res.data.data.results.map(item => ({
        value: item.id,
        label: item.type
      })));
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  const fetchArea = async () => {
    try {
      setLoadingAreas(true);
      const res = await axios.get(`/master/area?search=${searchArea}&limit=1000`);
      setAreas(res.data.data.results.map(area =>
      ({
        value: area.id,
        label: `${area.name} - PIC: ${area.pic.map(pic => pic.name).join(', ')}`
      })
      ));
      setLoadingAreas(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  const fetchData = async () => {
    try {
      setLoadingData(true);
      const res = await axios.get(`/user/report-finding/${edit}`);
      setFinding(findings.find(item => item.value === res.data.data.finding.id));
      setDescription(res.data.data.description);
      setSolution(res.data.data.solution);
      setPriority(priorities.find(item => item.value === res.data.data.priority));
      setActionDone(res.data.data.actionDone);
      setStatus(statuses.find(item => item.value === res.data.data.status));
      setArea(areas.find(item => item.value === res.data.data.areas.id));
      setLoadingData(false);

    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  useEffect(() => {
    if (open) {
      if (!findings.length) fetchFinding();
      if (!areas.length) fetchArea();
      if (!loading && !loadingAreas) fetchData();
    }
    // eslint-disable-next-line
  }, [open, search, edit, loading, loadingAreas]);

  const handleClose = () => {
    setActionDone('');
    setFinding(null);
    setDescription('');
    setSolution('');
    setPriority(null);
    setStatus(null);
    onClose();
  }

  const submit = async (e) => {
    e.preventDefault();
    const data = {
      areaId: area.value,
      findingId: finding.value,
      description,
      solution,
      priority: priority.value,
      actionDone,
      status: status.value
    }

    try {
      const res = await axios.put(`/user/finding-dashboard/${edit}`, data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      handleClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{edit ? t('component.title.editFinding') : t('component.title.createFinding')}</DialogTitle>
      {(open && (loadingData || loading || loadingAreas) && <Loading />)}
      {(open && !loadingData && !loading && !loadingAreas) && (
        <form onSubmit={submit}>
          <DialogContent>
            <DialogContentText>
              {t('component.title.editFindingDesc')}
            </DialogContentText>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, value) => setArea(value)}
              onKeyUp={(event) => setSearchArea(event.target.value)}
              value={area}
              id="combo-box-demo"
              options={areas}
              sx={{ width: '100%' }}
              renderInput={(params) => {
                params.fullWidth = true;
                params.margin = "dense";
                params.required = true;
                return <TextField {...params} label={t('component.text.area')} />
              }}
            />
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, value) => setFinding(value)}
              onKeyUp={(event) => setSearch(event.target.value)}
              id="combo-box-demo"
              value={finding}
              options={findings}
              sx={{ width: '100%' }}
              renderInput={(params) => {
                params.fullWidth = true;
                params.margin = "dense";
                params.required = true;
                return <TextField {...params} label={t('component.text.finding')} />
              }}
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              multiline
              rows={3}
              name="description"
              label={t("component.text.description")}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              required
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              multiline
              rows={3}
              name="solution"
              label={t("component.text.solutionSuggested")}
              value={solution}
              onChange={(e) => setSolution(e.target.value)}
              type="text"
              required
            />
            <TextField
              margin="dense"
              autoFocus
              fullWidth
              multiline
              rows={3}
              name="actionDone"
              label={t("component.text.actionDone")}
              value={actionDone}
              onChange={(e) => setActionDone(e.target.value)}
              type="text"
              required={status?.value === 1}
            />
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, value) => setPriority(value)}
              value={priority}
              id="combo-box-demo"
              options={priorities}
              sx={{ width: '100%' }}
              renderInput={(params) => {
                params.fullWidth = true;
                params.margin = "dense";
                params.required = true;
                return <TextField {...params} label={t('component.text.priority')} />
              }}
            />
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, value) => setStatus(value)}
              value={status}
              id="combo-box-demo"
              options={statuses}
              sx={{ width: '100%' }}
              renderInput={(params) => {
                params.fullWidth = true;
                params.margin = "dense";
                params.required = true;
                return <TextField {...params} label={t('component.text.status')} />
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t('component.button.cancel')}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {t('component.button.update')}
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  )
}