import { useState, useEffect } from 'react';
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import { Card, Container, Grid, Button, Table, TableHead, TableRow, TableCell, styled, alpha, InputBase, TablePagination, TableBody, Chip } from '@mui/material';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Area from '../../components/filters/Area';
import ConfirmApprove from '../../components/finding-list/ConfirmApprove';
import ConfirmReject from '../../components/finding-list/ConfirmReject';

import fileDownload from 'js-file-download';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Form() {
  const { t } = useTranslation();
  const location = useLocation();
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [id, setId] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [area, setArea] = useState('');

  const fetchData = async () => {
    try {
      const result = await axios.get(`/user/finding-dashboard?page=${page}&limit=${rowsPerPage}&search=${search}&area=${area}&status=2`);
      setData(prev => result.data.data.results);
      setTotal(result.data.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, rowsPerPage, search, area]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const loc = location.pathname;
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    getSubmenu();
    // eslint-disable-next-line
  }, [])

  const handleFilterArea = (id) => {
    setArea(id);
    setPage(0);
  }

  const handleCloseApprove = () => {
    setOpenApprove(false);
    setId(null);
    fetchData();
  };

  const handleCloseReject = () => {
    setOpenReject(false);
    setId(null);
    fetchData();
  };

  const handleApprove = async (id) => {
    try {
      const res = await axios.put(`/admin/approve-finding/${id}`, { status: 1 });
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      handleCloseApprove();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  const handleReject = async (id) => {
    try {
      const res = await axios.put(`/admin/approve-finding/${id}`, { status: 0 });
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      handleCloseReject();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  const exportExcel = async () => {
    try {
      const result = await axios.get(`/user/finding-dashboard/excel?search=${search}&area=${area}&status=2`, {
        responseType: 'blob'
      });
      // get filename from header
      const filename = result.headers['content-disposition'].split('filename=')[1];
      fileDownload(result.data, filename);
    } catch (err) {
      console.log(err);
    }
  }

  if (loading) return <Loading />

  return (
    <Container>
      <ConfirmApprove open={openApprove} onClose={handleCloseApprove} handleApprove={handleApprove} id={id} />
      <ConfirmReject open={openReject} onClose={handleCloseReject} handleReject={handleReject} id={id} />
      <h1>{title}</h1>
      <Card sx={{ padding: 5 }}>
        <Grid container justifyContent={"space-between"} alignItems="center" sx={{ padding: 2 }}>
          <Grid item>
            <Grid container alignItems="center" spacing={1} justifyContent="flex-start">
              <Grid item xs="auto">
                <Button variant="contained" onClick={exportExcel}>
                  Export
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Search>
                  <SearchIconWrapper>
                    <FontAwesomeIcon icon={"fa-solid fa-magnifying-glass"} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    value={search}
                    onChange={(e) => handleSearch(e)}
                    placeholder={`${t("component.text.search")}...`}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container alignItems="center" spacing={1} justifyContent="flex-end">
              <Grid item xs={4}>
                <Area setArea={handleFilterArea} area={area} />
              </Grid>
              <Grid item xs="auto">
                <Button variant="contained" color="warning" onClick={
                  () => {
                    setSearch('');
                    setArea('');
                    setPage(0);
                  }
                }>
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('component.text.location')}</TableCell>
              <TableCell>{t('component.text.date')}</TableCell>
              <TableCell>{t('component.text.auditor')}</TableCell>
              <TableCell>{t('component.text.finding')}</TableCell>
              <TableCell>{t('component.text.description')}</TableCell>
              <TableCell>{t('component.text.solutionSuggested')}</TableCell>
              <TableCell>{t('component.text.priority')}</TableCell>
              <TableCell>{t('component.text.status')}</TableCell>
              <TableCell>{t('component.text.pic')}</TableCell>
              <TableCell width="30%" align="center">{t('component.text.action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
              <TableRow>
                <TableCell colSpan={10} align="center">{t('component.text.noData')}</TableCell>
              </TableRow>
            )}
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.areas.name}</TableCell>
                <TableCell>{new Date(item.date * 1000).toLocaleDateString('id-ID')}</TableCell>
                <TableCell>{item.user.name}</TableCell>
                <TableCell>{item.finding.type}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.solution}</TableCell>
                <TableCell>{item.priority === 1 ? t('component.text.low') : (item.priority === 2 ? t('component.text.medium') : t('component.text.high'))}</TableCell>
                <TableCell>{item.status === 1 ? <Chip label={t('component.text.closed')} color="success" /> : (item.status === 2) ? <Chip label={t('component.text.verification')} color="warning" /> : <Chip label={t('component.text.open')} color="danger" />}</TableCell>
                <TableCell>{item.areas.pic.map(item => item.name).join(', ')}</TableCell>
                <TableCell>
                  <Grid container spacing={1} justifyContent="center" alignItems="center" direction="column">
                    <Grid item xs="auto">
                      <Link to={`/finding-list/${item.id}`}>
                        <Button variant="contained" color="primary" size="small">
                          {t('component.button.detail')}
                        </Button>
                      </Link>
                    </Grid>
                    {(item.areas.pic.find(item => item.id === JSON.parse(localStorage.getItem('user')).id) || JSON.parse(localStorage.getItem("user")).roles.find(role => role.roleId === 'SFT')) && (
                      <>
                        <Grid item xs="auto">
                          <Button variant="contained" color="success" size="small" onClick={(e) => {
                            setOpenApprove(prev => true);
                            setId(prev => item.id);
                          }}>{t('component.button.approve')}</Button>
                        </Grid>
                        <Grid item xs="auto">
                          <Button variant="contained" color="danger" size="small" onClick={(e) => {
                            setOpenReject(prev => true);
                            setId(prev => item.id);
                          }}>{t('component.button.reject')}</Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
