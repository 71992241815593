import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";


export default function UpdateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const minValue = 1;
  const maxValue = 100;
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [icon, setIcon] = useState('');
  const [order, setOrder] = useState(minValue);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const res = await axios.get(`/master/menu/${id}`);
        const data = res.data.data;
        setName(data.name);
        setUrl(data.url);
        setIcon(data.icon);
        setOrder(data.order);
        setIsActive(data.isActive === 1 ? true : false);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'url':
        setUrl(value);
        break;
      case 'icon':
        setIcon(value);
        break;
      case 'order':
        setOrder(prev => Math.min(Math.max(e.target.value, minValue), maxValue));
        break;
      case 'isActive':
        setIsActive(prev => !prev);
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, url, icon, order, isActive };

    try {
      const res = await axios.put(`/master/menu/${id}`, data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setName('');
      setUrl('');
      setIcon('');
      setOrder(minValue);
      setIsActive(false);
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.editMenu')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('component.title.editMenuDesc')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label={t('component.text.name')}
            type="text"
            fullWidth
            value={name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="url"
            label="URL"
            type="text"
            fullWidth
            value={url}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="icon"
            label={t('component.text.icon')}
            type="text"
            fullWidth
            value={icon}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="order"
            label={t('component.text.order')}
            type="number"
            min="1"
            max="99"
            fullWidth
            value={order}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isActive}
                onChange={handleChange}
                name="isActive"
                color="primary"
              />
            }
            label={t('component.text.active')}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('component.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}