import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Autocomplete, Card, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import theme from "../../mui-theme";
import Loading from "../../Loading";
import MonthYear from "../../filters/MonthYear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Dashboard() {
  const location = useLocation();
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingReport, setLoadingReport] = useState(true);

  const [forms, setForms] = useState([]);
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line
  const [month, setMonth] = useState(new Date().getMonth());
  // eslint-disable-next-line
  const [year, setYear] = useState(new Date().getFullYear());

  const [dataReport, setDataReport] = useState({});
  const [dataProgress, setDataProgress] = useState(null);

  const fetchForms = async () => {
    try {
      const result = await axios.get(`/admin/dca-form?limit=1000&search=${search}`);
      setForms(result.data.data.results.map((item) => ({ value: item.id, label: item.name })));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataReport = async () => {
    try {
      setLoadingReport(true);
      const result = await axios.get(`/user/dca-audit/report/${selected?.value}?month=${month === "all" ? "" : month}&year=${year}`);
      setDataReport(result.data);
      setLoadingReport(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProgress = async () => {
    try {
      const result = await axios.get(`/user/dca-audit/schedule/dashboard?month=${month === "all" ? "" : month}&year=${year}`);
      setDataProgress(result.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const loc = location.pathname;
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    getSubmenu();
    fetchDataReport();
    fetchProgress();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchForms();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    fetchDataReport();
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    fetchDataReport();
    fetchProgress();
    setLoading(false);
    // eslint-disable-next-line
  }, [month, year]);

  if (loading) return <Loading />;

  return (
    <Container maxWidth="xl">
      <h1>{title}</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <MonthYear month={month} setMonth={setMonth} year={year} setYear={setYear} isAll={true} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Typography variant="h5">{`Actual VS Planned - ${month === "all" ? "" : t(`month.${month + 1}`)} ${year}`}</Typography>

            {dataProgress && (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={dataProgress} style={{ fontSize: "1rem" }} layout="vertical" margin={{ top: 0, right: 50, left: 50, bottom: 30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis dataKey="name" type="category" />
                  <XAxis type="number" />
                  <Bar dataKey="value" fill={theme.palette.primary.main} isAnimationActive={true}>
                    <LabelList dataKey="value" position="right" offset={14} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </Card>
        </Grid>
      </Grid>
      <Card sx={{ mt: 2, p: 5 }}>
        <Typography variant="h4">{t("page.dca.reportDetails")}</Typography>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(event, value) => setSelected(value)}
          onKeyUp={(event) => setSearch(event.target.value)}
          id="combo-box-demo"
          value={selected}
          options={forms}
          sx={{ width: "100%" }}
          renderInput={(params) => {
            params.fullWidth = true;
            params.margin = "dense";
            params.required = true;
            return <TextField {...params} label="Audit" />;
          }}
        />
        {loadingReport && <Loading />}
        {!loadingReport && dataReport && dataReport?.chart?.length > 0 && (
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} md={12}>
              <TableContainer sx={{ maxHeight: 330 }}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("component.text.name")}</TableCell>
                      <TableCell>{t("component.text.date")}</TableCell>
                      <TableCell align="center">{t("component.text.status")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!dataReport?.schedule?.length && (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          {t("component.text.noData")}
                        </TableCell>
                      </TableRow>
                    )}
                    {dataReport?.schedule?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.userName}</TableCell>
                        <TableCell>{new Date(item.date * 1000).toLocaleDateString("id-ID")}</TableCell>
                        <TableCell align="center">
                          {item.status === 1 ? (
                            <FontAwesomeIcon icon={"fa-solid fa-check"} style={{ color: "green" }} />
                          ) : (
                            <FontAwesomeIcon icon={"fa-solid fa-times"} style={{ color: "red" }} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={dataReport.chart} style={{ fontSize: "1rem" }} margin={{ top: 30, right: 0, left: 0, bottom: 60 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={localStorage.getItem("language") === "en" ? "nameEn" : "nameId"} type="category" angle={-18} interval={0} textAnchor="end" />
                  <YAxis type="number" domain={[0, 100]} tickFormatter={(percentage) => `${percentage}%`} />

                  <Bar dataKey="value" fill={theme.palette.primary.main} isAnimationActive={false}>
                    <LabelList dataKey="value" position="top" offset={14} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <TableContainer sx={{ maxWidth: "100%" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" rowSpan={2}>
                        No
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        {t("component.text.section")}
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        {t("component.text.question")}
                      </TableCell>
                      <TableCell align="center" colSpan={dataReport.users.length}>
                        {t("component.text.auditor")}
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        Non Compliance
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        Total Non Compliance
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {dataReport.users.map((item, index) => (
                        <TableCell key={index}>{item.name}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataReport.categories.map((cat, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell>{localStorage.getItem("language") === "en" ? cat.nameEn : cat.nameId}</TableCell>
                        <TableCell>
                          <Table>
                            <TableBody>
                              {cat?.dcaFormQuestion?.map((item, index) => (
                                <TableRow
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <TableCell>{localStorage.getItem("language") === "en" ? item.nameEn : item.nameId}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                        {dataReport.users.map((user, index) => (
                          <TableCell key={index} align="center">
                            {cat?.dcaFormQuestion?.map((question, index) => (
                              <Table>
                                <TableCell key={index} align="center">
                                  {dataReport.detail.find((item) => item.questionId === question.id && item.userId === user.id)
                                    ? dataReport.detail.find((item) => item.questionId === question.id && item.userId === user.id).percentage
                                    : 0}
                                </TableCell>
                              </Table>
                            ))}
                          </TableCell>
                        ))}
                        <TableCell align="center">
                          {dataReport.detail.find((item) => item.catId === cat.id)
                            ? (
                                (dataReport.detail
                                  .filter((item) => item.catId === cat.id)
                                  .reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.percentage), 0) /
                                  (dataReport.users.length * dataReport.categories.find((category) => category.id === cat.id)?.dcaFormQuestion?.length)) *
                                100
                              ).toFixed(2)
                            : 0}
                          %
                        </TableCell>
                        {index === 0 && (
                          <TableCell
                            align="center"
                            rowSpan={dataReport.categories.reduce(
                              (accumulator, currentValue) =>
                                accumulator + (currentValue.dcaFormQuestion.length > 1 ? currentValue.dcaFormQuestion.length + 1 : 1),
                              0
                            )}
                          >
                            {dataReport.detail
                              ? dataReport.categories
                                  .reduce(
                                    (accumulator, currentValue) =>
                                      accumulator +
                                      (dataReport.detail
                                        .filter((item) => item.catId === currentValue.id)
                                        .reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.percentage), 0) /
                                        (dataReport.users.length * currentValue?.dcaFormQuestion?.length)) *
                                        100,
                                    0
                                  )
                                  .toFixed(2) /
                                (dataReport.users.length * dataReport.categories.length)
                              : 0}
                            %
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </Card>
    </Container>
  );
}
