import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function SetToDone({ open, onClose, idFinding = null }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { id } = useParams();

  const [totalAfter, setTotalAfter] = useState(1);
  const [actionDone, setActionDone] = useState('');
  const [imagesAfter, setImagesAfter] = useState([]);
  const [imageNamesAfter, setImageNamesAfter] = useState(['', '', '']);

  useEffect(() => {
    if (open) {
      setTotalAfter(1);
      setActionDone('');
      setImagesAfter([]);
      setImageNamesAfter(['', '', '']);
    }
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { actionDone, imagesAfter };

    try {
      const res = await axios.put(`/user/report-finding/${id ?? idFinding}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.setToDone')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('component.title.setToDoneDesc')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            autoFocus
            fullWidth
            multiline
            rows={3}
            name="actionDone"
            label={t("component.text.actionDone")}
            value={actionDone}
            onChange={(e) => setActionDone(e.target.value)}
            type="text"
            required
          />
          {Array.from(Array(totalAfter).keys()).map((item, index) => (
            <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2} key={index}>
              <Grid item xs={8}>
                <TextField
                  margin="dense"
                  autoFocus
                  fullWidth
                  name="image"
                  label={t("component.text.imageAfter")}
                  value={imageNamesAfter[index]}
                  type="text"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                >
                  {t('component.button.upload')}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => {
                      const newImagesAfter = [...imagesAfter];
                      newImagesAfter[index] = e.target.files[0];
                      setImagesAfter(newImagesAfter);

                      const newImageNamesAfter = [...imageNamesAfter];
                      newImageNamesAfter[index] = e.target.files[0].name;
                      setImageNamesAfter(newImageNamesAfter);
                    }}
                  />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  component="label"
                  color="danger"
                  fullWidth
                  onClick={() => {
                    if (totalAfter > 1) {
                      setTotalAfter(totalAfter - 1);
                      const newImagesAfter = [...imagesAfter];
                      newImagesAfter.splice(index, 1);
                      setImagesAfter(newImagesAfter);
                    }
                  }}
                >
                  {t('component.button.delete')}
                </Button>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setTotalAfter((prev) => {
              return prev > 2 ? prev : prev + 1
            })}
          >
            {t('component.button.addImageAfter')}
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('component.button.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}