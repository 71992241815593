import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import { Toaster } from "react-hot-toast";

import ProtectedRoute from "./components/ProtectedRoute";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Activate from "./pages/auth/Activate";
import Menu from "./pages/auth/Menu";
import Finding from "./pages/admin/Finding";
import Area from "./pages/admin/Area";
import Department from "./pages/admin/Department";

import Notification from "./pages/Notification";
import Profile from "./pages/Profile";

import DCAAudit from "./pages/dca-audit/Dashboard";
import DCAMasterForm from "./pages/dca-audit/MasterForm";
import DCAFormDetail from "./pages/dca-audit/FormDetail";
import DCAFormView from "./pages/dca-audit/FormView";
import DCAList from "./pages/dca-audit/List";
import DCAListDetail from "./pages/dca-audit/ListDetail";
import DCASchedule from "./pages/dca-audit/Schedule";
import DCAMemberConfig from "./pages/dca-audit/MemberConfiguration";
import DCAImportMemberConfig from "./pages/dca-audit/ImportMemberConfiguration";
import DCAArea from "./pages/dca-audit/MasterArea";

import ProjectAudit from "./pages/project-audit/Dashboard";
import ProjectMasterCategory from "./pages/project-audit/MasterCategory";
import ProjectMasterForm from "./pages/project-audit/MasterForm";
import ProjectFormDetail from "./pages/project-audit/FormDetail";
import ProjectFormView from "./pages/project-audit/FormView";
import ProjectList from "./pages/project-audit/List";
import ProjectListDetail from "./pages/project-audit/ListDetail";

import SMAT from "./pages/smat/Dashboard";
import SMATForm from "./pages/smat/Form";

import ReportFinding from "./pages/report-finding/Dashboard";
import ReportFindingForm from "./pages/report-finding/Form";

import FindingList from "./pages/finding-list/Dashboard";
import FindingListDetail from "./pages/finding-list/Detail";
import FindingVerification from "./pages/finding-list/Verification";

import TrackingAuditExternal from "./pages/tracking-audit-external/Dashboard";

import AdminDashboard from "./pages/admin/Dashboard";
import MenuManagement from "./pages/admin/MenuManagement";
import SubmenuManagement from "./pages/admin/SubmenuManagement";
import UserManagement from "./pages/admin/UserManagement";
import ImportUser from "./pages/admin/ImportUser";
import Roles from "./pages/admin/Roles";
import RoleDetail from "./pages/admin/RoleDetail";
import EmailTemplate from "./pages/admin/EmailTemplates";

import QSFTDashboard from "./pages/qsft/Dashboard";
import QSFTMasterPlan from "./pages/qsft/MasterPlan";
import QSFTAudit from "./pages/qsft/Audit";
import QSFTAuditForm from "./pages/qsft/Form";
import QSFTMemberConfig from "./pages/qsft/MemberConfiguration";
import QSFTImportMemberConfig from "./pages/qsft/ImportMemberConfiguration";
import QSFTReport from "./pages/qsft/Report";

const Error404 = React.lazy(() => import("./pages/404"));
const Error403 = React.lazy(() => import("./pages/403"));

const history = React.lazy(() => import("./history"));

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Toaster
          toastOptions={{
            position: "top-right",
            style: {
              fontSize: "1rem",
            },
          }}
        />

        <React.Suspense fallback={<h1>Still Loading…</h1>}>
          <Routes history={history}>
            <Route
              path="/"
              element={
                <ProtectedRoute loginOnly={true}>
                  <Header noSidebar={true}>
                    <Menu />
                  </Header>
                </ProtectedRoute>
              }
            />

            {/* =============AUTH============= */}
            <Route
              path="/auth/login"
              element={
                <ProtectedRoute loginOnly={false}>
                  <Login />
                </ProtectedRoute>
              }
            />
            <Route
              path="/auth/register"
              element={
                <ProtectedRoute loginOnly={false}>
                  <Register />
                </ProtectedRoute>
              }
            />
            <Route
              path="/auth/forgot-password"
              element={
                <ProtectedRoute loginOnly={false}>
                  <ForgotPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/auth/reset-password/:token"
              element={
                <ProtectedRoute loginOnly={false}>
                  <ResetPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/auth/activate/:token"
              element={
                <ProtectedRoute loginOnly={false}>
                  <Activate />
                </ProtectedRoute>
              }
            />

            <Route
              path="/notification"
              element={
                <ProtectedRoute loginOnly={true}>
                  <Header noSidebar={true}>
                    <Notification />
                  </Header>
                </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute loginOnly={true}>
                  <Header noSidebar={true}>
                    <Profile />
                  </Header>
                </ProtectedRoute>
              }
            />

            {/* =============MASTER DATA============= */}
            <Route
              path="/master-data"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <AdminDashboard />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-data/user-management"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <UserManagement />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-data/user-management/import"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <ImportUser />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-data/menu-management"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <MenuManagement />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-data/submenu-management"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <SubmenuManagement />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route path="/master-data/roles">
              <Route
                path="/master-data/roles/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <Roles />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <RoleDetail />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/master-data/master-finding"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <Finding />
                  </Header>
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-data/department"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <Department />
                  </Header>
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-data/master-area"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <Area />
                  </Header>
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-data/email-template"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <EmailTemplate />
                  </Header>
                </ProtectedRoute>
              }
            />

            {/* =============DCA AUDIT============= */}
            <Route
              path="/dca-audit"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <DCAAudit />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route path="/dca-audit/master-form">
              <Route
                path="/dca-audit/master-form/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <DCAMasterForm />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <DCAFormDetail />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/dca-audit/form">
              <Route
                path="/dca-audit/form/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <DCAFormView />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":idParams"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <DCAFormView />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/dca-audit/list">
              <Route
                path="/dca-audit/list"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <DCAList />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <DCAListDetail />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/dca-audit/schedule"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <DCASchedule />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dca-audit/member-config"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <DCAMemberConfig />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dca-audit/member-config/import"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <DCAImportMemberConfig />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dca-audit/area"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <DCAArea />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            {/* =============PROJECT AUDIT============= */}
            <Route
              path="/project-audit"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <ProjectAudit />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route path="/project-audit/master-form">
              <Route
                path="/project-audit/master-form/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <ProjectMasterForm />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <ProjectFormDetail />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/project-audit/master-audit-categories"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <ProjectMasterCategory />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route path="/project-audit/form">
              <Route
                path="/project-audit/form/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <ProjectFormView />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              {/* <Route path=":id/" element={<ProtectedRoute loginOnly={true} protect={true}>
                <Header>
                  <ProjectFormView />
                </Header>
                <Footer />
              </ProtectedRoute>} /> */}
            </Route>
            <Route path="/project-audit/list">
              <Route
                path="/project-audit/list"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <ProjectList />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <ProjectListDetail />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>

            {/* =============SMAT============= */}
            <Route path="/management-safety-audit">
              <Route
                path="/management-safety-audit/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <SMAT />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <FindingListDetail />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/management-safety-audit/form"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <SMATForm />
                  </Header>
                </ProtectedRoute>
              }
            />

            {/* =============REPORT FINDING============= */}
            <Route
              path="/report-finding"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <ReportFinding />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/report-finding/form"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <ReportFindingForm />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            {/* =============FINDING LIST============= */}
            <Route path="/finding-list">
              <Route
                path="/finding-list/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <FindingList />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <FindingListDetail />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/finding-list/closed-verification"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <FindingVerification />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            {/* =============TRACKING AUDIT EXTERNAL============= */}
            <Route
              path="/tracking-audit-external/"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <TrackingAuditExternal />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            {/* =============QSFT============= */}
            <Route
              path="/qsft/"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <QSFTDashboard />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/qsft/master-plan"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <QSFTMasterPlan />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/qsft/member-config"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <QSFTMemberConfig />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/qsft/member-config/import"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <QSFTImportMemberConfig />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/qsft/report"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <QSFTReport />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route path="/qsft/audit/form">
              <Route
                path="/qsft/audit/form"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <QSFTAuditForm />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":topicId"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <QSFTAuditForm />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/qsft/audit">
              <Route
                path="/qsft/audit/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <QSFTAudit />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <QSFTAuditForm />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/qsft/audit/detail">
              <Route
                path="/qsft/audit/detail"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <QSFTAudit />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <QSFTAuditForm />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>

            {/* =============ERROR============= */}
            <Route path="/error/404" element={<Error404 />} />
            <Route path="/error/403" element={<Error403 />} />
            <Route path="/*" element={<Error404 />} />
          </Routes>
        </React.Suspense>
      </header>
    </div>
  );
}

export default App;
