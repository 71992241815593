import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MonthYear from "../../components/filters/MonthYear";

export default function Report() {
  const { t } = useTranslation();
  const location = useLocation();
  const loc = location.pathname;
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);

  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    getSubmenu();
  }, [axios, loc]);

  const handleExport = async () => {
    try {
      const res = await axios.get(`/admin/qsft/report/export?month=${month}&year=${year}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `QSFT Report ${t(`month.${month + 1}`)} - ${year}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  if (loading) return <Loading />;

  return (
    <Container>
      <h1>{title}</h1>
      <Paper
        xs={12}
        sx={{
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: 4,
          borderRadius: 2,
          marginBottom: 2,
        }}
      >
        <Grid container justifyContent={"start"} spacing={2}>
          <Grid item xs={12}>
            <MonthYear month={month} setMonth={setMonth} year={year} setYear={setYear} />
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <Button onClick={handleExport} color="success" variant="contained" size="small">
              <FontAwesomeIcon icon={"fa-solid fa-file-excel"} /> <Typography sx={{ ml: 1 }}>{t("component.button.generateReport")}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
