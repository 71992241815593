import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import theme from "../../components/mui-theme";
import Loading from "../../components/Loading";
import MonthYear from "../../components/filters/MonthYear";

const COLORS = [
  theme.palette.primary.main,
  theme.palette.secondary.main,
  theme.palette.error.main,
  theme.palette.warning.main,
  theme.palette.info.main,
  theme.palette.success.main,
];

export default function Dashboard() {
  const location = useLocation();
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line
  const [month, setMonth] = useState(new Date().getMonth());
  // eslint-disable-next-line
  const [year, setYear] = useState(new Date().getFullYear());

  const [data, setData] = useState([]);
  const [chart, setChart] = useState([]);

  const drawerWidth = 290;

  const fetchData = async () => {
    try {
      const response = await axios.get(`/user/project-audit/report-table?month=${month}&year=${year}`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(t("error"));
    }
  };

  const fetchChart = async () => {
    try {
      const response = await axios.get(`/user/project-audit/report-chart?month=${month}&year=${year}`);
      setChart(response.data.data);
    } catch (error) {
      toast.error(t("error"));
    }
  };

  const fetchAll = async () => {
    setLoading(true);
    await fetchData();
    await fetchChart();
    setLoading(false);
  };

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const loc = location.pathname;
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    getSubmenu();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAll();
    // eslint-disable-next-line
  }, [month, year]);

  if (loading) return <Loading />;

  return (
    <div>
      <h1>{title}</h1>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <MonthYear month={month} setMonth={setMonth} year={year} setYear={setYear} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 2, mb: 2, maxWidth: `calc(100vw - ${drawerWidth}px)` }}>
            <Typography variant="h5">{`${t("page.project.projectReport")} - ${t(`month.${month + 1}`)} ${year}`}</Typography>
            <TableContainer sx={{ maxWidth: `calc(100vw - ${drawerWidth}px)` }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" rowSpan={3}>
                      No
                    </TableCell>
                    <TableCell align="center" rowSpan={3}>
                      {t("component.text.category")}
                    </TableCell>
                    <TableCell align="center" colSpan={data.weeks.length * 2}>
                      {t(`month.${month + 1}`)}
                    </TableCell>
                    <TableCell align="center" rowSpan={2} colSpan={6}>
                      {t("component.text.cumulativeData")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {data.weeks.map((week, index) => (
                      <TableCell align="center" colSpan={2} key={`${index}a`}>
                        {t("component.text.week")} {index + 1}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {data.weeks.map((week, index) => (
                      <>
                        <TableCell align="center" key={`${index}b`}>
                          {t("component.text.auditedPoint")}
                        </TableCell>
                        <TableCell align="center" key={`${index}c`}>
                          Non-compliance
                        </TableCell>
                      </>
                    ))}
                    <TableCell align="center">Total Audit</TableCell>
                    <TableCell align="center">%Audit</TableCell>
                    <TableCell align="center">Non-compliance</TableCell>
                    <TableCell align="center">%Non-compliance vs Total</TableCell>
                    <TableCell align="center">%Non-compliance each category</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.categories.map((category, index) => (
                    <TableRow key={`${index}d`}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>{category.name}</TableCell>
                      {data.weeks.map((week, index) => (
                        <>
                          <TableCell align="center" key={`${index}e`}>
                            {data.answers.find((answer) => answer.week === index + 1).answer.find((answer) => answer.categoryId === category.id)?.total || ""}
                          </TableCell>
                          <TableCell align="center" key={`${index}f`}>
                            {data.answers.find((answer) => answer.week === index + 1).answer.find((answer) => answer.categoryId === category.id)
                              ?.noncompliance || ""}
                          </TableCell>
                        </>
                      ))}
                      <TableCell align="center">
                        {data.answers.reduce((acc, answer) => acc + parseInt(answer.answer.find((answer) => answer.categoryId === category.id)?.total || 0), 0)}
                      </TableCell>
                      <TableCell align="center">
                        {/* total all*/}
                        {(
                          (data.answers.reduce(
                            (acc, answer) => acc + parseInt(answer.answer.find((answer) => answer.categoryId === category.id)?.total || 0),
                            0
                          ) /
                            data.answers.reduce((acc, answer) => acc + answer.answer.reduce((acc, answer) => acc + parseInt(answer.total || 0), 0), 0)) *
                          100
                        ).toFixed(2)}
                        %
                      </TableCell>
                      <TableCell align="center">
                        {data.answers.reduce(
                          (acc, answer) => acc + parseInt(answer.answer.find((answer) => answer.categoryId === category.id)?.noncompliance || 0),
                          0
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {(
                          (data.answers.reduce(
                            (acc, answer) => acc + parseInt(answer.answer.find((answer) => answer.categoryId === category.id)?.noncompliance || 0),
                            0
                          ) /
                            data.answers.reduce(
                              (acc, answer) => acc + answer.answer.reduce((acc, answer) => acc + parseInt(answer.noncompliance || 0), 0),
                              0
                            )) *
                          100
                        ).toFixed(2)}
                        %
                      </TableCell>
                      <TableCell align="center">
                        {(
                          (data.answers.reduce(
                            (acc, answer) => acc + parseInt(answer.answer.find((answer) => answer.categoryId === category.id)?.noncompliance || 0),
                            0
                          ) /
                            data.answers.reduce(
                              (acc, answer) => acc + parseInt(answer.answer.find((answer) => answer.categoryId === category.id)?.total || 0),
                              1
                            )) *
                          100
                        ).toFixed(2)}
                        %
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right" colSpan={2 + data.weeks.length * 2}>
                      Total
                    </TableCell>
                    <TableCell align="center">
                      {data.answers.reduce((acc, answer) => acc + answer.answer.reduce((acc, answer) => acc + parseInt(answer.total || 0), 0), 0)}
                    </TableCell>
                    <TableCell align="center">
                      {(
                        (data.answers.reduce((acc, answer) => acc + answer.answer.reduce((acc, answer) => acc + parseInt(answer.total || 0), 0), 0) /
                          data.answers.reduce((acc, answer) => acc + answer.answer.reduce((acc, answer) => acc + parseInt(answer.total || 0), 0), 0)) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                    <TableCell align="center">
                      {data.answers.reduce((acc, answer) => acc + answer.answer.reduce((acc, answer) => acc + parseInt(answer.noncompliance || 0), 0), 0)}
                    </TableCell>
                    <TableCell align="center">
                      {(
                        (data.answers.reduce((acc, answer) => acc + answer.answer.reduce((acc, answer) => acc + parseInt(answer.noncompliance || 0), 0), 0) /
                          data.answers.reduce((acc, answer) => acc + answer.answer.reduce((acc, answer) => acc + parseInt(answer.noncompliance || 0), 0), 0)) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
        {chart && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2 }}>
                  <Typography variant="h5">{t("page.project.implementation")}</Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={chart}
                        dataKey="total"
                        nameKey="category"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        label
                        fill={[theme.palette.primary.main, theme.palette.secondary.main, theme.palette.error.main]}
                      >
                        {chart.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2 }}>
                  <Typography variant="h5">Non-Compliance</Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={chart}
                        dataKey="noncompliance"
                        nameKey="category"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        label
                        fill={[theme.palette.primary.main, theme.palette.secondary.main, theme.palette.error.main]}
                      >
                        {chart.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
