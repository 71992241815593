import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Card, Container, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import theme from "../../components/mui-theme";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loading from "../../components/Loading";

const cardStyle = {
  padding: 2,
  "&:hover": {
    top: "-40px",
    transform: "translateY(-10px)",
  },
  transition: "transform 0.3s",
};

export default function Menu() {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [menuList, setMenuList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `Menu - ${process.env.REACT_APP_NAME}`;

    const fetchData = async () => {
      try {
        const result = await axiosPrivate.get("/user/menu");
        setMenuList(result.data.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  if (loading) return <Loading />;

  return (
    <Container>
      <div style={{ marginBottom: 50 }}>
        <Typography variant="h3">
          {t("page.auth.menu.welcome")}, <strong style={{ color: theme.palette.primary.main }}>{JSON.parse(localStorage.getItem("user")).name}</strong>
        </Typography>
      </div>
      <Grid container spacing={3} justifyContent="center">
        {menuList.map((item, index) => (
          <Grid item xs={index < 3 ? true : 3} key={index}>
            <Link to={item.url}>
              <Card sx={cardStyle}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item></Grid>
                      <Grid item xs="auto">
                        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                          <FontAwesomeIcon icon={item.icon} />
                        </Avatar>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" noWrap component="div" color="primary">
                      {item.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
