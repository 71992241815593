import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ProtectedRoute = ({ children, loginOnly = true, protect = false }) => {
  const axios = useAxiosPrivate();
  const token = Cookies.get("isLoggedIn");
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [access, setAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const isNull = !user?.email || !user?.departmentId || !user?.employeeId || !user?.position;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loc = location.pathname.split("/")[1];
        const result = await axios.get(`/user/menu-url/${loc}`);
        setAccess(result.data.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    if (protect) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [location]);

  if (token && loginOnly && isNull && location.pathname !== "/profile") {
    return <Navigate to={"/profile"} />;
  }

  if (token && !loginOnly) {
    return <Navigate to={"/"} />;
  }

  if (!token && loginOnly) {
    return <Navigate to={"/auth/login"} />;
  }

  if (protect && !access && !loading) {
    return <Navigate to={"/error/403"} />;
  }

  return children;
};

export default ProtectedRoute;
