import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { Card, Container, Typography } from "@mui/material";
import Detail from "../../components/dca-audit/view-form/Detail";
import Loading from "../../components/Loading";

export default function FormDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const res = await axios.get(`/user/dca-audit/list/${id}`);
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      navigate(-1);
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [])


  if (loading) return <Loading />

  return (
    <>
      <Container>
        <Card sx={{ padding: 5 }}>
          {id && data && (
            <>
              <Typography variant="h4">{data.dcaForm.name}</Typography>
              <img src={`${process.env.REACT_APP_API_URL}/${data.dcaForm.imageHeader}`} alt={data.dcaForm.name} style={{
                maxWidth: '25vw',
                maxHeight: '25vw',
                margin: 30
              }} />
              <Detail data={data} id={id} />
            </>
          )}
        </Card>
      </Container>
    </>
  )
}