import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function UpdateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [name, setName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/master/department/${id}`);
        const data = res.data.data;
        setName(data.name);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name };

    try {
      const res = await axios.put(`/master/department/${id}`, data);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setName("");
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t("component.title.editDepartment")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("component.title.editDepartmentDesc")}</DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField autoFocus margin="dense" name="name" label={t("component.text.name")} type="text" fullWidth value={name} onChange={handleChange} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("component.button.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t("component.button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
