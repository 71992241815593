import { CircularProgress, Grid } from "@mui/material";

export default function Loading() {
  return (
    <div>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs="auto">
          <CircularProgress color="primary" />
        </Grid>
        <Grid item xs="auto">
          <h1>Loading...</h1>
        </Grid>
      </Grid>
    </div>
  )
}