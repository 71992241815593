import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    "Accept-Language": localStorage.getItem("language") ?? 'en',
  },
})

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    "Accept-Language": localStorage.getItem("language") ?? 'en',
  },
})