import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, TextField } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";


export default function CreateModal({ open, onClose }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const minValue = 1;
  const maxValue = 100;
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [imageName, setImageName] = useState('');
  const [order, setOrder] = useState(minValue);
  const [isActive, setIsActive] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'image':
        const file = e.target.files[0];
        setImage(file);
        setImageName(file.name);
        break;
      case 'order':
        setOrder(prev => Math.min(Math.max(e.target.value, minValue), maxValue));
        break;
      case 'isActive':
        setIsActive(prev => !prev);
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, image, order, isActive };

    try {
      const res = await axios.post("/admin/dca-form", data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setName('');
      setImage('');
      setImageName('');
      setOrder(minValue);
      setIsActive(false);
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.createForm')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('component.title.createNewFormDesc')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label={t('component.text.name')}
            type="text"
            fullWidth
            value={name}
            onChange={handleChange}
            required
          />
          <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2}>
            <Grid item xs={9}>
              <TextField
                autoFocus
                margin="dense"
                label={t('component.text.imageHeader')}
                type="text"
                fullWidth
                value={imageName}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" component="label">
                {t('component.button.upload')}
                <input hidden accept="image/*" type="file" name="image" onChange={handleChange} />
              </Button>
            </Grid>
          </Grid>
          <TextField
            margin="dense"
            name="order"
            label={t('component.text.order')}
            type="number"
            min="1"
            max="99"
            fullWidth
            value={order}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isActive}
                onChange={handleChange}
                name="isActive"
                color="primary"
              />
            }
            label={t('component.text.active')}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('component.button.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}