import { useState, useEffect } from 'react';
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Container, Grid, Button, Typography, TextField, Autocomplete } from '@mui/material';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";
import { DateTimePicker } from '@mui/x-date-pickers';

export default function Form() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);

  const [findings, setFindings] = useState([]);
  const [search, setSearch] = useState('');

  const [areas, setAreas] = useState([]);
  const [searchArea, setSearchArea] = useState('');

  const [totalBefore, setTotalBefore] = useState(1);

  const priorities = [
    { value: 1, label: t('component.text.low') },
    { value: 2, label: t('component.text.medium') },
    { value: 3, label: t('component.text.high') },
  ];

  const [finding, setFinding] = useState(null);
  const [description, setDescription] = useState('');
  const [solution, setSolution] = useState('');
  const [priority, setPriority] = useState(null);
  const [area, setArea] = useState(null);
  const [datetime, setDatetime] = useState(new Date());

  const [imagesBefore, setImagesBefore] = useState([]);
  const [imageNamesBefore, setImageNamesBefore] = useState(['', '', '']);

  const fetchFinding = async () => {
    try {
      const res = await axios.get(`/master/finding?search=${search}`);
      setFindings(res.data.data.results.map(item => ({
        value: item.id,
        label: item.type
      })));
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  const fetchArea = async () => {
    try {
      const res = await axios.get(`/master/area${searchArea ? `?search=${searchArea}` : ''}`);
      setAreas(res.data.data.results.map(area =>
      ({
        value: area.id,
        label: `${area.name} - PIC: ${area.pic.map(pic => pic.name).join(', ')}`
      })
      ));
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const loc = location.pathname;
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    }

    getSubmenu();
    fetchFinding();
    fetchArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchArea])

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      areaId: area.value,
      findingId: finding.value,
      datetime: Math.floor(new Date(datetime).getTime() / 1000),
      description,
      solution,
      priority: priority.value,
      imagesBefore,
    }
    try {
      const res = await axios.post('/user/report-finding/form', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      navigate('/report-finding');
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
    setLoading(false);
  }

  if (loading) return <Loading />

  return (
    <Container>
      <h1>{title}</h1>
      <Card sx={{ padding: 5 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>{t('page.reportFinding.create')}</Typography>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, value) => setArea(value)}
            onKeyUp={(event) => setSearchArea(event.target.value)}
            id="combo-box-demo"
            options={areas}
            sx={{ width: '100%' }}
            renderInput={(params) => {
              params.fullWidth = true;
              params.margin = "dense";
              params.required = true;
              params.placeholder = t('component.text.searchArea');
              return <TextField {...params} label={t('component.text.area')} />
            }}
          />
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, value) => setFinding(value)}
            onKeyUp={(event) => setSearch(event.target.value)}
            id="combo-box-demo"
            value={finding}
            options={findings}
            sx={{ width: '100%' }}
            renderInput={(params) => {
              params.fullWidth = true;
              params.margin = "dense";
              params.required = true;
              return <TextField {...params} label={t('component.text.finding')} />
            }}
          />
          <DateTimePicker
            label={t('component.text.dateTime')}
            value={datetime ?? new Date()}
            name="datetime"
            ampm={false}
            inputFormat="DD/MM/YYYY HH:mm"
            onChange={(newValue) => {
              setDatetime(newValue);
            }}
            renderInput={(params) => {
              params.fullWidth = true;
              params.margin = "dense";
              params.required = true;
              return <TextField {...params} />
            }}
          />
          <TextField
            margin="dense"
            autoFocus
            fullWidth
            multiline
            rows={3}
            name="description"
            label={t("component.text.description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            required
          />
          <TextField
            margin="dense"
            autoFocus
            fullWidth
            multiline
            rows={3}
            name="solution"
            label={t("component.text.solutionSuggested")}
            value={solution}
            onChange={(e) => setSolution(e.target.value)}
            type="text"
            required
          />
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, value) => setPriority(value)}
            value={priority}
            id="combo-box-demo"
            options={priorities}
            sx={{ width: '100%' }}
            renderInput={(params) => {
              params.fullWidth = true;
              params.margin = "dense";
              params.required = true;
              return <TextField {...params} label={t('component.text.priority')} />
            }}
          />
          {Array.from(Array(totalBefore).keys()).map((item, index) => (
            <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2} key={index}>
              <Grid item xs={8}>
                <TextField
                  margin="dense"
                  autoFocus
                  fullWidth
                  name="image"
                  label={t("component.text.imageBefore")}
                  value={imageNamesBefore[index]}
                  type="text"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                >
                  {t('component.button.upload')}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => {
                      const newImagesBefore = [...imagesBefore];
                      newImagesBefore[index] = e.target.files[0];
                      setImagesBefore(newImagesBefore);

                      const newImageNamesBefore = [...imageNamesBefore];
                      newImageNamesBefore[index] = e.target.files[0].name;
                      setImageNamesBefore(newImageNamesBefore);
                    }}
                  />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  component="label"
                  color="danger"
                  fullWidth
                  onClick={() => {
                    if (totalBefore > 1) {
                      setTotalBefore(totalBefore - 1);
                      const newImagesBefore = [...imagesBefore];
                      newImagesBefore.splice(index, 1);
                      setImagesBefore(newImagesBefore);
                    }
                  }}
                >
                  {t('component.button.delete')}
                </Button>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mb: 2 }}
            onClick={() => setTotalBefore((prev) => {
              return prev > 2 ? prev : prev + 1
            })}
          >
            {t('component.button.addImageBefore')}
          </Button>
          <Button variant="contained" color="primary" sx={{ mt: 5 }} type="submit" disabled={loading}>
            {t('component.button.submit')}
          </Button>
        </form>
      </Card>
    </Container>
  );
}