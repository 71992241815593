import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Loading from "../../Loading";

export default function CreateModal({ open, onClose, id = null }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [query, setQuery] = useState('');
  const [params, setParams] = useState([]);
  // eslint-disable-next-line
  const [loadingParam, setLoadingParam] = useState(false);
  const [loading, setLoading] = useState(false);

  const showParams = async () => {
    try {
      setLoadingParam(true);
      const res = await axios.post(`/master/show-params`, {
        query
      });
      setParams(res.data.data);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
    setLoadingParam(false);
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/master/email-template/${id}`);
      setName(res.data.data.name);
      setSubject(res.data.data.subject);
      setBody(res.data.data.body);
      setQuery(res.data.data.query);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setName('');
    setSubject('');
    setBody('');
    setQuery('');
    setParams([]);
    if (id) fetchData();
    // eslint-disable-next-line
  }, [id]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { name, subject, body, query };
    try {
      const res = id ? await axios.put(`/master/email-template/${id}`, data) : await axios.post("/master/email-template", data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setName('');
      setSubject('');
      setBody('');
      setQuery('');
      setParams([]);
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
    setLoading(false);
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="md">
      <DialogTitle id="form-dialog-title">{id ? t('component.title.editEmailTemplate') : t('component.title.createEmailTemplate')}</DialogTitle>
      <DialogContent>
        {loading && <Loading />}
        {!loading && (
          <>
            <DialogContentText>
              {id ? t('component.title.editEmailTemplateDesc') : t('component.title.createNewEmailTemplateDesc')}
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={8} >
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  name="name"
                  label={t('component.text.name')}
                  type="text"
                  fullWidth
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <TextField
                  autoFocus
                  required
                  multiline
                  rows={4}
                  margin="dense"
                  name="query"
                  label="Query"
                  type="text"
                  fullWidth
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                />
                <Button variant="contained" color="warning" type="button" onClick={showParams} sx={{ mb: 1 }}>
                  {t('component.text.showParam')}
                </Button>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  name="subject"
                  label={t('component.text.subject')}
                  type="text"
                  fullWidth
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                />
                <div style={{ marginTop: 10 }}>
                  <ReactQuill
                    theme="snow"
                    value={body}
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, 3, 4, 5, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                      ]
                    }}
                    placeholder={t('component.text.body')}
                    onChange={setBody}
                    style={{
                      height: 300,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle" component="p">
                  {t('component.text.availableParams')}
                </Typography>
                {loadingParam && <Loading />}
                {!loadingParam && params.map(param => (
                  <Typography variant="subtitle" component="p" key={param}>
                    {param}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained" disabled={loading}>
          {id ? t('component.button.update') : t('component.button.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}