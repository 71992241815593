import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DatePicker } from "@mui/x-date-pickers";

export default function CreateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [topic, setTopic] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");

  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/admin/qsft/master-plan/${id}`);
        const data = res.data.data;
        setTopic(data.topic);
        setStartDate(new Date(data.startDate * 1000));
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    setTopic("");
    setStartDate(new Date());

    if (id) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [id, open]);

  const handleChange = (e) => {
    const { name } = e.target;
    switch (name) {
      case "image":
        const file = e.target.files[0];
        setImage(file);
        setImageName(file.name);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("attachment", image);
      formData.append("topic", topic);
      formData.append("startDate", Math.floor(new Date(startDate).getTime() / 1000));
      const res = id
        ? await axios.put(`/admin/qsft/master-plan/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        : await axios.post("/admin/qsft/master-plan", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setTopic("");
      setStartDate(new Date());
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{id ? t("component.title.editMasterPlan") : t("component.title.createMasterPlan")}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>{id ? t("component.title.editMasterPlanDesc") : t("component.title.createMasterPlanDesc")}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="topic"
            label={t("component.text.topic")}
            type="text"
            fullWidth
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            required
          />
          <DatePicker
            label={t("component.text.date")}
            value={startDate}
            name="startDate"
            inputFormat="MM/YYYY"
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            views={["month", "year"]}
            renderInput={(params) => {
              params.fullWidth = true;
              params.margin = "dense";
              params.required = true;
              return <TextField {...params} />;
            }}
          />
          <Grid container justifyContent="center" direction="row" alignItems="center" spacing={2}>
            <Grid item xs={9}>
              <TextField autoFocus margin="dense" label={t("component.text.planDocument")} type="text" fullWidth value={imageName} required />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" component="label">
                {t("component.button.upload")}
                <input
                  hidden
                  accept=".doc,.docx,application/msword,application/pdf,image/jpeg,image/jpg,image/png"
                  type="file"
                  name="image"
                  onChange={handleChange}
                />
              </Button>
            </Grid>
          </Grid>
          <small>(.docx, .xslx, .pdf, .jpeg, .jpg, .png)</small>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t("component.button.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {id ? t("component.button.update") : t("component.button.create")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
