import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function CreateModal({ open, onClose }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'isActive':
        setIsActive(prev => !prev);
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, isActive };

    try {
      const res = await axios.post("/admin/project-category", data);
      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });
      setName('');
      setIsActive(false);
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('component.title.createCategory')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('component.title.createNewCategoryDesc')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label={t('component.text.name')}
            type="text"
            fullWidth
            value={name}
            onChange={handleChange}
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isActive}
                onChange={handleChange}
                name="isActive"
                color="primary"
              />
            }
            label={t('component.text.active')}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('component.button.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('component.button.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}