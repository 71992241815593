import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  alpha,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Dashboard() {
  const userName = JSON.parse(localStorage.getItem("user")).name;
  const isAdmin = JSON.parse(localStorage.getItem("user")).roles.find((role) => role.roleId === "ADM" || role.roleId === "SU") ? true : false;
  const userId = JSON.parse(localStorage.getItem("user")).id;
  const { t } = useTranslation();
  const location = useLocation();
  const loc = location.pathname;
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ id: userId, name: userName });
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");

  const fetchMember = async () => {
    try {
      const res = await axios.get(`/admin/qsft/member-config/leaders/${selectedUser.id}`);
      const data = res.data.data;
      setMembers(data);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    const getSubmenu = async () => {
      try {
        const res = await axios.post(`/user/submenu-url/`, { url: loc });
        setTitle(res.data.data.name);
        document.title = `${res.data.data.name} - ${process.env.REACT_APP_NAME}`;
        setLoading(false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    const getLeaders = async () => {
      try {
        const res = await axios.get(`/admin/qsft/member-config/leaders`);
        setUsers(res.data.data);
        setFilteredUsers(res.data.data);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    getSubmenu();
    getLeaders();
    fetchMember();
  }, [axios, loc]);

  useEffect(() => {
    const fetch = async () => {
      setLoadingData(true);
      try {
        const res = await axios.get(`/admin/qsft/dashboard${selectedUser?.id ? "/" + selectedUser.id : ""}`);
        setData(res.data.data);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
      setLoadingData(false);
    };

    fetch();
    fetchMember();
  }, [selectedUser, axios]);

  const handleExport = async () => {
    try {
      const res = await axios.get(`/admin/qsft/dashboard/export`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Dashboard ${selectedUser.name}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setFilteredUsers(
      users.filter((user) => {
        return user.user.name.toLowerCase().includes(e.target.value.toLowerCase());
      })
    );
  };

  if (loading) return <Loading />;

  return (
    <Container>
      <h1>{title}</h1>
      <Paper
        xs={12}
        sx={{
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" sx={{ m: 2 }}>
          {t("page.qsft.dashboard")} {selectedUser.name}
        </Typography>
        <Grid container justifyContent={"start"} spacing={2}>
          {isAdmin && (
            <Grid item xs={12} md={users.length > 0 && 3}>
              <Search>
                <SearchIconWrapper>
                  <FontAwesomeIcon icon={"fa-solid fa-magnifying-glass"} />
                </SearchIconWrapper>
                <StyledInputBase
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder={`${t("component.text.search")}...`}
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
              <Box
                sx={{
                  border: 1,
                  marginTop: 1,
                  borderColor: "divider",
                  borderRadius: 1,
                  padding: 1,
                  maxHeight: "50vh",
                  overflow: "auto",
                }}
              >
                <List>
                  {filteredUsers && (
                    <>
                      {filteredUsers.map((user) => (
                        <ListItem key={user.id} disablePadding>
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: "center",
                              px: 2.5,
                            }}
                            selected={user.user.id === selectedUser?.id}
                            onClick={() => {
                              setSelectedUser(user.user);
                            }}
                          >
                            <ListItemText primary={user.user.name} sx={{ display: "block" }} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </>
                  )}
                </List>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={filteredUsers.length > 0 && isAdmin ? 9 : 12}>
            {loadingData && <Loading />}
            {data && !loadingData && (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" rowSpan={2}>
                      {t("component.text.member")}
                    </TableCell>
                    <TableCell align="center" colSpan={12}>
                      {t("component.text.presence")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {Array.from({ length: 12 }).map((_, index) => (
                      <TableCell key={index} align="center">
                        {t(`shortMonth.${index + 1}`)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!members?.length && (
                    <TableRow>
                      <TableCell colSpan={13} align="center">
                        {t("component.text.noData")}
                      </TableCell>
                    </TableRow>
                  )}
                  {members.map((member, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{member.user.name}</TableCell>
                      {data.map((item, index) => (
                        <TableCell align="center">
                          {item.plan?.participants.find((participant) => participant.userId === member.user.id)?.presence ? (
                            <FontAwesomeIcon icon={"fa-solid fa-check"} style={{ color: "green" }} />
                          ) : (
                            <FontAwesomeIcon icon={"fa-solid fa-times"} style={{ color: "red" }} />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          {isAdmin && (
            <Grid item xs={12} textAlign={"end"}>
              <Button onClick={handleExport} color="success" variant="contained" size="small">
                <FontAwesomeIcon icon={"fa-solid fa-file-excel"} /> <Typography sx={{ ml: 1 }}>{t("component.button.exportToExcel")}</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}
