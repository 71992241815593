import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card, Container, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";
import NoncomplianceView from "../../components/project-audit/form/NoncomplianceView";

export default function FormDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [idView, setIdView] = useState(null);

  const [actions, setActions] = useState({});
  const [edit, setEdit] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios.get(`/user/project-audit/list/${id}`);
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
      navigate('/project-audit/list');
    }
  }

  const handleClose = () => {
    setOpen(false);
    setIdView(null);
  }

  useEffect(() => {
    document.title = `Form Detail - ${process.env.REACT_APP_NAME}`;
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])

  if (loading) return <Loading />

  return (
    <>
      <Container>
        <NoncomplianceView open={open} onClose={handleClose} id={idView} />
        <Card sx={{ padding: 5 }}>
          <Typography variant="h5">Focused Checklsit #{data.form.order}</Typography>
          <Typography variant="h3"><strong>{data.form.name}</strong></Typography>
          <Typography>{data.answer.area.name}</Typography>
          <Grid container sx={{ marginTop: 8 }} spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                autoFocus
                fullWidth
                name="contractor"
                label={t("component.text.contractor")}
                value={data.answer.contractorName}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                autoFocus
                fullWidth
                name="contractor"
                label={t('component.text.dateTime')}
                value={new Date(data.answer.date * 1000).toLocaleString('id-ID')}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                autoFocus
                fullWidth
                name="site"
                label={t("component.text.site")}
                value={data.answer.siteName}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                autoFocus
                fullWidth
                name="auditor"
                label={t("component.text.auditor")}
                value={data.answer.user.name}
                type="text"
              />
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Typography variant="body1">{t("component.title.projectAuditDesc")}</Typography>
          <Divider sx={{ marginTop: 2 }} />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: '5%' }}>No.</TableCell>
                <TableCell align="center">{t('component.text.question')}</TableCell>
                <TableCell sx={{ width: '30%' }} align="center">{t('component.text.answer')}</TableCell>
                <TableCell sx={{ width: '15%' }} align="center">{t('component.text.action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.form.projectQuestion.map((question, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell>{localStorage.getItem('language') === 'en' ? question.nameEn : question.nameId}</TableCell>
                  <TableCell align="center">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="answer"
                        value={data.answer.projectAnswerDetail.find(item => item.projectQuestionId === question.id)?.answer === 0 ? '0' : data.answer.projectAnswerDetail.find(item => item.projectQuestionId === question.id)?.answer === 1 ? '1' : " "}
                      >
                        <FormControlLabel value="1" control={<Radio />} label={t('component.text.yes')} />
                        <FormControlLabel value="0" control={<Radio />} label={t('component.text.no')} />
                        <FormControlLabel value=" " control={<Radio />} label={"N/A"} />
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    {data.answer.projectAnswerDetail.find(item => item.projectQuestionId === question.id)?.answer === 0 && (
                      <Button variant="contained" color="primary" size="small" onClick={() => { setOpen(true); setIdView(data.answer.projectAnswerDetail.find(item => item.projectQuestionId === question.id).id) }}>
                        {t('component.button.viewAction')}
                      </Button>
                    )}

                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="right" colSpan={2}>
                  Total
                </TableCell>
                <TableCell align="center">
                  <Grid container>
                    <Grid item xs={6}>
                      {data.answer.projectAnswerDetail.filter(item => item.answer === 1).length}
                    </Grid>
                    <Grid item xs={6}>
                      {data.answer.projectAnswerDetail.filter(item => item.answer === 0).length}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Container>
    </>
  )
}