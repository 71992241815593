import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Status({ status, setStatus }) {
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    setStatuses([
      { id: 0, name: t('component.text.open') },
      { id: 1, name: t('component.text.closed') },
    ])
    // eslint-disable-next-line
  }, [])

  return (
    <FormControl fullWidth>
      <InputLabel id="status">Status</InputLabel>
      <Select
        labelId="status"
        id="status"
        label="Status"
        defaultValue=""
        fullWidth
        value={status}
        onChange={e => setStatus(e.target.value)}
      >
        {statuses && statuses.map((item, index) => (
          <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}